import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { font } from './font'
import { layouts } from './layouts'
import { spacing } from './spacing'

export { theme } from './theme'

export { breakpoints, colors, font, layouts, spacing }

export const tokens = {
    breakpoints,
    colors,
    font,
    layouts,
    spacing,
}
