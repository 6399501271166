import { vars } from '@snsw-gel/theming'
import { bindRefs, forwarded, useListener } from '@snsw-gel/utils'
import React, { Ref, useEffect, useRef, useState } from 'react'
import {
    PopOverShade,
    StyledPopOver,
    popOverTransformEndVar,
    popOverTransformOriginVar,
    popOverTransformStartVar,
    popOverTransitionDurationVar,
    popOverZIndexVar,
} from './StyledPopOver.styled'
import { OverlayTriggerState } from './useComboPickerState'
import classNames from 'classnames'
import FocusLock from 'react-focus-lock'

interface PopOverState {
    exiting: boolean
    entering: boolean
}

export interface PopOverProps extends OverlayTriggerState {
    'closeOnEscape'?: boolean
    'closeOnMouseLeave'?: boolean | HTMLElement
    'closeOnOutsideClick'?: boolean

    'transform'?: boolean | number
    'transformOrigin'?: string
    'transformStart'?: string
    'transformEnd'?: string

    'trapFocus'?: boolean

    'focusOnEnter'?: (
        containerElement?: HTMLElement,
    ) => HTMLElement | null | undefined
    'focusOnExit'?: () => HTMLElement | null | undefined

    'zIndex'?: number
    'shade'?: boolean

    'children': React.ReactNode | ((state: PopOverState) => React.ReactNode)

    'aria-labelledby'?: string
}

const durationToMs = (duration: string) => {
    if (duration.includes('ms')) {
        return Number(duration.replace('ms', ''))
    }
    if (duration.includes('s')) {
        return Number(duration.replace('s', '')) * 1000
    }
    return 0
}

export const PopOver = forwarded(
    (props: PopOverProps, outerRef: Ref<HTMLDivElement>) => {
        const {
            close,
            isOpen,
            open,
            setOpen: _,
            shade,
            closeOnOutsideClick,
            children,
            toggle,
            trapFocus = true,
            closeOnEscape,
            closeOnMouseLeave,
            transform = true,
            transformEnd,
            transformOrigin,
            transformStart,
            zIndex = 600,
            ['aria-labelledby']: ariaLabelledBy,
            ...rest
        } = props

        const TrapFocus = trapFocus ? FocusLock : React.Fragment

        const [animationState, setAnimationState] = useState<
            boolean | 'entering' | 'exiting'
        >(isOpen)
        const ref = useRef<HTMLDivElement>(null)

        // eslint-disable-next-line no-nested-ternary
        const duration = transform
            ? typeof transform === 'number'
                ? props.transform + 'ms'
                : vars.transitions.idle
            : '0ms'

        const target = typeof window !== 'undefined' ? window : null

        const isEscapable =
            animationState === true || animationState === 'entering'

        useListener(
            isEscapable && closeOnEscape ? target : null,
            'keydown',
            (e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                    close()
                }
            },
        )
        useListener(
            isEscapable && closeOnOutsideClick ? target : null,
            'click',
            (e: MouseEvent) => {
                if (closeOnOutsideClick) {
                    if (
                        ref.current &&
                        e.target &&
                        !ref.current.contains(e.target as Node)
                    ) {
                        close()
                    }
                }
            },
        )

        let closeOnMouseLeaveTarget = null
        if (isEscapable && closeOnMouseLeave) {
            if (closeOnMouseLeave !== true) {
                closeOnMouseLeaveTarget = closeOnMouseLeave
            } else {
                closeOnMouseLeaveTarget = ref.current
            }
        }
        useListener(closeOnMouseLeaveTarget, 'mouseleave', () => {
            if (props.closeOnMouseLeave) {
                close()
            }
        })

        const [lastOpen, setLastOpen] = useState(isOpen)

        useEffect(() => {
            // openness was affected by last action
            if (!ref.current) {
                return
            }

            const hasChanged = lastOpen !== isOpen

            const styles = getComputedStyle(ref.current!)

            const duration = styles.getPropertyValue('transition-duration')

            let timeout: any
            let frame: any

            if (isOpen) {
                setAnimationState(false)
                if (hasChanged) {
                    props.focusOnEnter?.(ref.current!)?.focus()
                }
                frame = requestAnimationFrame(() => {
                    setAnimationState('entering')
                    timeout = setTimeout(() => {
                        setAnimationState(true)
                    }, durationToMs(duration))
                })
            } else {
                setAnimationState('exiting')

                frame = requestAnimationFrame(() => {
                    timeout = setTimeout(() => {
                        setAnimationState(false)
                    }, durationToMs(duration))
                })
            }

            return () => {
                cancelAnimationFrame(frame)
                clearTimeout(timeout)
            }
        }, [isOpen])

        useEffect(() => {
            if (lastOpen !== isOpen) {
                setLastOpen(isOpen)
            }
        }, [lastOpen === isOpen])

        if (animationState || props.isOpen) {
            const childrenToRender =
                typeof children === 'function'
                    ? children({
                          entering: animationState === 'entering',
                          exiting: animationState === 'exiting',
                      })
                    : children
            return (
                <>
                    {shade && (
                        <PopOverShade
                            className={classNames(
                                animationState === 'entering' && '--mounted',
                                animationState === true && '--entered',
                            )}
                            style={{
                                ...(zIndex &&
                                    popOverZIndexVar.setStyle(
                                        zIndex.toString(),
                                    )),
                                ...(duration &&
                                    popOverTransitionDurationVar.setStyle(
                                        duration.toString(),
                                    )),
                            }}
                        />
                    )}
                    <StyledPopOver
                        {...rest}
                        ref={bindRefs(ref, outerRef)}
                        className={classNames(
                            // @ts-ignore
                            props.className,
                            animationState === 'entering' && '--mounted',
                            animationState === true && '--entered',
                        )}
                        role='dialog'
                        aria-modal='true'
                        aria-hidden={animationState === 'exiting'}
                        aria-labelledby={ariaLabelledBy}
                        style={{
                            ...(duration &&
                                popOverTransitionDurationVar.setStyle(
                                    duration.toString(),
                                )),
                            ...(transformOrigin &&
                                popOverTransformOriginVar.setStyle(
                                    transformOrigin,
                                )),
                            ...(transformStart &&
                                popOverTransformStartVar.setStyle(
                                    transformStart,
                                )),
                            ...(transformEnd &&
                                popOverTransformEndVar.setStyle(transformEnd)),
                            ...(zIndex &&
                                popOverZIndexVar.setStyle(zIndex.toString())),
                            // @ts-ignore
                            ...rest.style,
                        }}
                    >
                        <TrapFocus returnFocus disabled={!trapFocus}>
                            {childrenToRender}
                        </TrapFocus>
                    </StyledPopOver>
                </>
            )
        }

        return null
    },
)
