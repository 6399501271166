import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconQuestionProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconQuestion = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconQuestionProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            fill={displayColor}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.11455 2.75C7.31836 2.75 6.64643 2.9712 6.12118 3.43183C5.59351 3.89459 5.29327 4.54338 5.20187 5.35006L5.17358 5.59975L6.69523 5.76425L6.73445 5.53202C6.82302 5.00771 6.99145 4.64797 7.2156 4.42076C7.43385 4.19952 7.72832 4.08013 8.12576 4.08013C8.50884 4.08013 8.82382 4.2056 9.08585 4.45658C9.35214 4.71165 9.47432 4.99327 9.47432 5.3109C9.47432 5.46001 9.43884 5.61017 9.36284 5.7637C9.29507 5.90064 9.10071 6.12748 8.73475 6.45245C8.44451 6.70503 8.21453 6.92021 8.04778 7.09677C7.87416 7.2806 7.73124 7.47884 7.62067 7.69162C7.50995 7.9047 7.43554 8.12564 7.39929 8.3538C7.36422 8.57458 7.34852 8.90818 7.34852 9.34375V9.59375H8.80818L8.81321 9.34889C8.82441 8.80372 8.88447 8.47652 8.96316 8.32053C9.04629 8.15572 9.27206 7.89425 9.68264 7.52842C10.2238 7.05108 10.5989 6.634 10.7662 6.282C10.9254 5.94706 11.0064 5.59829 11.0064 5.23798C11.0064 4.52061 10.7258 3.91788 10.1758 3.44675C9.62713 2.97674 8.93316 2.75 8.11455 2.75ZM9.02914 11.395H7.07858V13.0449H9.02914V11.395Z'
                fill='#2E5299'
            />
        </svg>
    )
}
