import { useRef, useState } from 'react'
import { useMemoizedFn } from './useMemoizedFn'

export function useControllableValue<T = any, Rest extends any[] = []>(
    outerValue: undefined | T,
    onChange: undefined | ((value: T, ...rest: Rest) => void),
    defaultValue: T | undefined,
    initialState: T,
): [T, (value: T, ...rest: Rest) => void]
export function useControllableValue<T = any, Rest extends any[] = []>(
    outerValue: undefined | T,
    onChange: undefined | ((value: T, ...rest: Rest) => void),
    defaultValue: T,
    initialState?: T,
): [T, (value: T, ...rest: Rest) => void]
export function useControllableValue<T = any, Rest extends any[] = []>(
    outerValue: T,
    onChange?: (value: T, ...rest: Rest) => void,
    defaultValue?: T,
    initialState?: T,
): [T, (value: T, ...rest: Rest) => void]
export function useControllableValue<T = any, Rest extends any[] = []>(
    outerValue?: T,
    onChange?: (value: T, ...rest: Rest) => void,
    defaultValue?: T,
    initialState?: T,
) {
    const isControlled = outerValue !== undefined

    // @ts-ignore
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const wasControlledRef = useRef(isControlled)
        if (wasControlledRef.current !== isControlled) {
            console.warn(
                `A component is changing from ${
                    isControlled ? '' : 'un'
                }controlled to be ${isControlled ? 'un' : ''}controlled.`,
            )
        }
    }

    const [innerState, setInnerState] = useState(
        defaultValue === undefined ? initialState : defaultValue,
    )

    const value = isControlled ? outerValue : innerState

    function setState(result: T, ...rest: any[]) {
        if (!isControlled) {
            setInnerState(result)
        }

        // we always want to call the onChange handler
        if (onChange && !Object.is(result, value)) {
            // @ts-ignore
            onChange(result, ...(rest as []))
        }
    }

    return [value!, useMemoizedFn(setState)] as const
}
