import { breakpoints, spacing, font } from '@snsw-gel/tokens'
import { css } from 'styled-components'
// @ts-ignore
import { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import * as countryList from 'country-list'
import dayjs from 'dayjs'

export const focusObjectGenerator = arr => {
    const focusableElements = {
        all: arr,
        first: arr[0],
        last: arr[arr.length - 1],
        length: arr.length,
    }

    return focusableElements
}

export const getFocusableElement = el => {
    const elementArr = [].slice.call(
        el.querySelectorAll(`a[href],button:not([disabled]),
    area[href],input:not([disabled]):not([type=hidden]),
    select:not([disabled]),textarea:not([disabled]),
    iframe,object,embed,*:not(.is-draggabe)[tabindex],
    *[contenteditable]`),
    )

    return focusObjectGenerator(elementArr)
}

export const trapTabKey = (event, focusObject) => {
    const { activeElement } = document
    const focusableElement = focusObject

    if (event.keyCode !== 9) {
        return false
    }

    if (focusableElement.length === 1) {
        event.preventDefault()
    } else if (event.shiftKey && activeElement === focusableElement.first) {
        focusableElement.last.focus()
        event.preventDefault()
    } else if (!event.shiftKey && activeElement === focusableElement.last) {
        focusableElement.first.focus()
        event.preventDefault()
    }

    return true
}

export const pxToRem = size => `${size / 16}rem`

export const pxToEm = size => `${size / 16}em`

export const getSpacing = sizes => {
    const sizesArr = Array.isArray(sizes) ? sizes : [sizes]
    const theSizes = sizesArr.map(size => {
        let returnVal
        const spacingKeys = Object.keys(spacing)
        const tokenExists = spacingKeys.indexOf(size) !== -1
        const isNumber = /^\d+$/.test(size)

        if (tokenExists) {
            // eslint-disable-next-line security/detect-object-injection
            const spaceToken = spacing[size]
            returnVal = pxToRem(spaceToken)
        }
        if (!tokenExists && isNumber) {
            returnVal = pxToRem(size)
        }

        if (!tokenExists && !isNumber) {
            const err = new Error(
                'getSpacing function has been passed an invalid token.',
            )
            throw err
        }

        if (sizesArr.length > 4) {
            const err = new Error('getSpacing accepts arrays up to 4 values.')
            throw err
        }

        return returnVal
    })

    return theSizes.join(' ')
}

export const byteToMegabyte = byte => {
    const megabyte = (byte / (1024 * 1024)).toFixed(1)
    return megabyte
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
        sizes[Number(i)]
    }`
}

export class SyncPromise {
    resolved
    constructor(resolved) {
        this.resolved = resolved
    }
    then(callback) {
        const ret = callback(this.resolved)
        return new SyncPromise(ret)
    }
}

const mediaQuery =
    (...query) =>
    (...rules) => {
        // @ts-ignore
        const a = css(...query)
        // @ts-ignore
        const b = css(...rules)
        return css`
            @media ${a} {
                ${b}
            }
        `
    }

export const getBreakpoint = {
    xs: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.xs)})`,
    sm: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.sm)})`,
    md: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.md)})`,
    lg: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.lg)})`,
    xl: mediaQuery`screen and (min-width: ${pxToEm(breakpoints.xl)})`,
    print: mediaQuery`print`,
}

export const getTypeSize = (size, lineHeight = 'default') => {
    const lineHeightVal =
        lineHeight === 'heading'
            ? font.lineHeight.heading
            : font.lineHeight.default
    const typeSizeKeys = Object.keys(font.typeSize)
    const tokenExists = typeSizeKeys.indexOf(size) !== -1
    if (tokenExists) {
        // eslint-disable-next-line security/detect-object-injection
        const fontSize = font.typeSize[size]
        const output = css`
            font-size: ${pxToRem(fontSize[0])};
            line-height: ${lineHeightVal};

            ${getBreakpoint.md`
                font-size: ${pxToRem(fontSize[1])};
            `}
        `
        return output
    }
}

export const marginMixin = ({ margin }) => {
    const output = css`
        margin-top: ${margin ? getSpacing(margin.top) : pxToRem(16)};
    `

    return output
}

// For testing if the code is running in a client-side browser, or it's being server-side rendered (SSR).
// Usage:
//      import { canUseDom } from '/utils';
//      if (canUseDom) { ... }
export const canUseDom = Boolean(
    typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement,
)

export const useBoolean = initialValue => {
    const [value, setValue] = useState(initialValue)

    const setTrue = () => setValue(true)
    const setFalse = () => setValue(false)

    return [value, setTrue, setFalse]
}

export const Portal = ({ id, children }) => {
    const el = document.createElement('div')
    id && el.setAttribute('id', id)
    const elRef = useRef(el)
    useEffect(() => {
        const modalContainer = elRef.current
        document.body.appendChild(modalContainer)
        return () => {
            if (modalContainer.parentElement) {
                modalContainer.parentElement.removeChild(modalContainer)
            }
        }
    }, [])
    return createPortal(children, elRef.current)
}

export const detectDragDrop = () => {
    var div = document.createElement('div')
    return 'draggable' in div || ('ondragstart' in div && 'ondrop' in div)
}

// Get list of ISO 3166 countries
export const getCountries = () => countryList.getNames().sort()

export const getAustralianStates = () => [
    'ACT',
    'NSW',
    'NT',
    'QLD',
    'SA',
    'TAS',
    'VIC',
    'WA',
]

/**
 * Used to recall the previous value of a prop or state.
 * Save this to a variable for future comparison in your component.
 * @param {*} value
 * @returns the current reference value.
 */
export const usePrevious = value => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

/**
 * Used to check whether the component has rendered more than once.
 * @returns boolean; false on first render, true thereafter.
 */
export const useHasInitialised = () => {
    const [hasInitialised, setHasInitialised] = useState(false)
    useEffect(() => {
        setHasInitialised(true)
    }, [])
    return hasInitialised
}

/**
 * Forces callback function to wait given time before being called
 * @param {*} func callback function to perform logic
 * @param {*} wait time in milliseconds to wait between calls
 * @returns callback function
 */
export const debounce = (func, wait) => {
    let timerId
    return function (...args) {
        clearTimeout(timerId)

        timerId = setTimeout(() => {
            func.apply(this, args)
        }, wait)
    }
}

/**
 * Checks to see if the component is still mounted, leveraging useRef().
 * Use for ensuring component mounted before performing state updates, etc...
 * @returns boolean
 */
export const useMountedState = () => {
    const mountedRef = useRef(false)
    useEffect(() => {
        mountedRef.current = true
        return () => {
            mountedRef.current = false
        }
    }, [])
    return mountedRef.current
}

/**
 * Checks if object is empty.
 * @param {object} obj
 * @returns {boolean}
 */
export const isEmptyObj = obj =>
    !obj || obj.constructor !== Object || Object.entries(obj).length === 0

/**
 * Checks if array is empty.
 * @param {Array} arr
 * @returns {boolean}
 */
export const isEmptyArr = arr =>
    !arr || arr.constructor !== Array || arr.length === 0

/**
 * Removes a property from an object.
 * @param {string} propKey the key you'd like to remove.
 * @param {object} object the object you'd like to remove the key/value pair from.
 * @returns {object} the new object sans any instances of the key.
 */
export const removeObjectProperty = (propKey, object) => {
    // extract the propKey property and remaining items separately
    const { [propKey]: propValue, ...rest } = object
    // return only the remaining items
    return rest
}

/**
 * helper function to format a date with DayJS from ISO format
 * @param {string} date
 * @param {string} format
 * @returns a formatted date
 */
export const getFormattedDate = (date, format) => dayjs(date).format(format)

/**
 * helper function to conditionally render aria-describedby ids for errorMessage and helpMessage
 * @param {string} id
 * @param {boolean} hasError (hasError && errorMessage)
 * @param {boolean} hasHelper (helpMessage)
 * @returns a formatted aria-describedby string
 */
export const getAriaDescribedBy = (id, hasError, hasHelper) => {
    const idError = `${id}-error`
    const idHelper = `${id}-helper`

    const valueArray = []
    hasError && valueArray.push(idError)
    hasHelper && valueArray.push(idHelper)
    const valueString = valueArray.join(' ')

    return valueString ? valueString : null
}

export const showDeprecatedMsg = (msg, link) => {
    console.warn(`${msg}\n\nMore info: ${link}`)
}

/**
 * helper function to get ID from a pattern's value object
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} key
 * @param {object} value
 * @param {string} fallbackId
 * @returns an ID extracted from the value object by key, or the fallback ID
 */
const getId = (key, value, fallbackId) =>
    // eslint-disable-next-line security/detect-object-injection
    key && value && value[key]?.id ? value[key].id : fallbackId

/**
 * helper function to get label from a pattern's value object
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} key
 * @param {object} value
 * @param {string} fallbackLabel
 * @returns a label extracted from the value object by key, or the fallback label
 */
const getLabel = (key, value, fallbackLabel) =>
    // eslint-disable-next-line security/detect-object-injection
    key && value && value[key]?.label ? value[key].label : fallbackLabel

/**
 * helper function to get value from a pattern's value object
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} key
 * @param {object} value
 * @returns a value extracted from the value object by key, or an empty string
 */
const getValue = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    key && value && value[key]?.value ? value[key].value : ''

/**
 * helper function to get ref from a pattern's value object
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} key
 * @param {object} value
 * @returns a ref extracted from the value object by key, or null
 */
const getRef = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    key && value && value[key]?.inputRef ? value[key].inputRef : null

/**
 * helper function to get radio input value from a pattern's value object
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} key
 * @param {object} value
 * @returns value of object by key, or null
 */
const getRadioValue = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    !value ||
    !value[key] ||
    value[key]?.value === '' ||
    value[key]?.value === undefined
        ? null
        : // eslint-disable-next-line security/detect-object-injection
          value[key].value

/**
 * helper function to get component error state from a pattern's value object
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} key
 * @param {object} value
 * @returns an error boolean extracted from the value object by key
 */
const getHasError = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    value && !!value[key]?.errorMessage && '' !== value[key].errorMessage

/**
 * helper function to get component error message from a pattern's value object
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} key
 * @param {object} value
 * @returns an error message extracted from the value object by key
 */
const getErrorMessage = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    key && value && !!value[key]?.errorMessage ? value[key].errorMessage : ''

/**
 * Remove a given key from the pattern's value object.
 * @deprecated Pattern helpers are deprecated and will be removed in a future release.
 * @param {string} keyToStrip the key to locate and remove.
 * @param {object} value the pattern's value object.
 * @returns {object} the value object sans any instances of the key.
 */
const stripKeyFromValue = (keyToStrip, value) => {
    const newValue = { ...value }
    Object.entries(newValue).forEach(([key, val]) => {
        newValue[`${key}`] = removeObjectProperty(
            keyToStrip,
            newValue[`${key}`],
        )
    })
    return newValue
}

/**
 * @deprecated patternUtils are deprecated and will be removed in a future release.
 */
export const patternUtils = {
    /** @deprecated */
    getId,
    /** @deprecated */
    getRef,
    /** @deprecated */
    getLabel,
    /** @deprecated */
    getValue,
    /** @deprecated */
    getRadioValue,
    /** @deprecated */
    getHasError,
    /** @deprecated */
    getErrorMessage,
    /** @deprecated */
    stripKeyFromValue,
}
