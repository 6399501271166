import React from 'react'
import { StyledHorizontalRule } from './HorizontalRule.styled'

export type HrProps = {
    className?: string
}

export const HorizontalRule = (props: HrProps) => {
    return <StyledHorizontalRule {...props} />
}

// @ts-ignore
HorizontalRule.displayName = 'HorizontalRule'
