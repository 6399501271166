import { ForwardedRef, ReactElement, RefAttributes, forwardRef } from 'react'

export function forwarded<T, P = {}>(
    render: (props: P, ref: ForwardedRef<T>) => ReactElement | null,
): (props: P & RefAttributes<T>) => ReactElement | null {
    // @ts-ignore
    const propTypes = render.propTypes
    // @ts-ignore
    delete render['propTypes']
    // @ts-ignore
    const defaultProps = render.defaultProps
    // @ts-ignore
    delete render['defaultProps']

    // @ts-ignore
    const result = forwardRef(render) as any

    if (propTypes) {
        // @ts-ignore
        result.propTypes = render.propTypes
    }

    if (defaultProps) {
        // @ts-ignore
        result.defaultProps = render.defaultProps
    }

    return result
}
