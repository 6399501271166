import dayjs, { Dayjs } from 'dayjs'
// NOTE: file extension is required for the esmodules to work!
import isBetween from 'dayjs/plugin/isBetween.js'
// NOTE: file extension is required for the esmodules to work!
import customParseFormat from 'dayjs/plugin/customParseFormat.js'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

const dateBetween = (value: Dayjs, minDate: Dayjs, maxDate: Dayjs) =>
    value.isBetween(minDate, maxDate, 'days', '[]') // all inclusive

const validDateFormat = (value: string, dateFormat: string) =>
    dayjs(value, dateFormat, true).isValid()

const Validators = {
    dateBetween,
    validDateFormat,
}

export default Validators
