import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { vars, mq } from '@snsw-gel/theming'
import { Heading } from '@snsw-gel/content'
import { pxToRem, getSpacing, getBreakpoint } from '@snsw-gel/utils'

export const StyledInPageAlert = styled.div`
    ${props =>
        props.compact &&
        css`
            padding: ${getSpacing('sm')} !important;
        `};
    line-height: ${vars.font.lineHeight.default};
    border-left: 6px solid ${vars.colors.accent3.default};
    padding: ${getSpacing(['sm', 'sm', 'sm', 12])};
    margin: ${getSpacing(['sm', 'none'])};

    a {
        color: ${vars.colors.bg.text};
        cursor: pointer;
        text-decoration: underline;
        svg {
            fill: ${vars.colors.bg.text};
        }
        &:focus {
            outline: 2px solid ${vars.colors.focus};
            outline-offset: 2px;
        }
    }

    h2 {
        font-size: ${vars.font.size.base};
        line-height: ${vars.font.lineHeight.default};
        font-weight: ${vars.font.weight.bold};
    }

    h2,
    li,
    ol,
    p,
    ul {
        margin: ${getSpacing(['none', 'none', 8])};
        max-width: 100%;
    }

    li:last-child,
    ol:last-child,
    p:last-child,
    ul:last-child {
        margin-bottom: 0;
    }

    ${props => props.variant && InPageAlertStyle};

    &:focus {
        outline: 2px solid ${vars.colors.focus};
        outline-offset: 2px;
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        &:focus {
            -ms-high-contrast-adjust: none;
            forced-color-adjust: none;
            outline-color: Highlight;
            background-color: Background;
            border-color: InactiveBorder;
            color: InfoText;
        }
    }

    ${mq.min('tablet')} {
        padding: ${getSpacing(['md', 'md', 'md', 'sm'])};
        ${props =>
            !props.variant &&
            `
            padding: ${getSpacing(['md', 'md', 'md', 54])};
        `}
        background-position: ${getSpacing([12, 'md'])};
    }

    ${mq.print()} {
        background: none !important;
        padding: ${getSpacing(['sm', 'none', 'sm', 'sm'])};
    }
`

const InPageAlertStyle = css`
    ${props =>
        props.variant === 'error' &&
        `
        background-color: ${vars.colors.error.off};
        border-color: ${vars.colors.error};
    `}
    ${props =>
        props.variant === 'success' &&
        `
        background-color: ${vars.colors.success.off};
        border-color: ${vars.colors.success};
    `}
    ${props =>
        props.variant === 'info' &&
        `
        background-color: ${vars.colors.info.off};
        border-color: ${vars.colors.info};
    `}
    ${props =>
        props.variant === 'warning' &&
        `
        background-color: ${vars.colors.warning.off};
        border-color: ${vars.colors.warning};
    `}
    ${props =>
        props.variant === 'callout' &&
        `
        border-color: ${vars.colors.info};
    `}
`

StyledInPageAlert.propTypes = {
    info: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    success: PropTypes.bool,
    plain: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.string,
}

export const StyledInPageAlertWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;

    ${mq.min('lgMobile')} {
        flex-flow: nowrap;
    }

    ${props =>
        props.compact &&
        css`
            flex-flow: nowrap;
        `};
`

export const StyledInPageAlertTitle = styled(Heading)`
    font-size: ${vars.font.size.base};
    line-height: ${vars.font.lineHeight.default};
    font-weight: ${vars.font.weight.bold};
    max-width: 100%;

    /* we need this to be more specific than heading styles */
    &.heading-6 {
        margin: 0;
        margin-top: 0;
        margin-bottom: ${getSpacing(8)};
        &.title-only {
            margin-bottom: 0;
        }
    }
`

export const StyledInPageAlertIcon = styled.div`
    padding: ${getSpacing(['none', 'sm', 'none', 'none'])};
    &.compact-icon {
        height: ${pxToRem(24)};
    }
    svg {
        width: ${pxToRem(24)};
        height: ${pxToRem(24)};
    }
`

export const StyledInPageAlertChildren = styled.div`
    ${mq.min('lgMobile')} {
        margin: ${getSpacing(['none', 'none', 'none', 40])};
    }

    &.--callout {
        ${mq.min('lgMobile')} {
            margin: ${getSpacing(['none', 'none', 'none', 'none'])};
        }

        a {
            color: ${vars.colors.accent3.default};
            svg {
                fill: ${vars.colors.accent3};
            }
            &:focus {
                outline: 2px solid ${vars.colors.focus};
                outline-offset: 2px;
            }
        }
    }
`

export const StyledInPageAlertCompact = styled.div`
    * {
        display: inline;
        &:not(:first-child) {
            margin-left: 7px;
        }
    }
`
