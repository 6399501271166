import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconAddLicenceProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconAddLicence = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconAddLicenceProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.AddLicence_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M11 22a5 5 0 105-5 5 5 0 00-5 5z' fill='#d7153a' />
            <path
                className='AddLicence_svg__cls-2'
                d='M41 25.23V14a3 3 0 00-3-3H10a3 3 0 00-3 3v17a3 3 0 003 3h20a9 9 0 1011-8.77zM10 32a1 1 0 01-1-1V14a1 1 0 011-1h28a1 1 0 011 1v11a9 9 0 00-8.77 7zm29 9a7 7 0 117-7 7 7 0 01-7 7z'
            />
            <path
                className='AddLicence_svg__cls-2'
                d='M43 33h-3v-3a1 1 0 00-2 0v3h-3a1 1 0 000 2h3v3a1 1 0 002 0v-3h3a1 1 0 000-2zM24 21h10a1 1 0 000-2H24a1 1 0 000 2zm11 3a1 1 0 00-1-1H24a1 1 0 000 2h10a1 1 0 001-1zM2 24V7a1 1 0 011-1h28a1 1 0 011 1v2h2V7a3 3 0 00-3-3H3a3 3 0 00-3 3v17a3 3 0 003 3h2v-2H3a1 1 0 01-1-1z'
            />
        </svg>
    )
}
