import styled from 'styled-components'
import { getBreakpoint } from '@snsw-gel/utils'
import { vars } from '@snsw-gel/theming'
import { textLinkStyles } from '@snsw-gel/text-link'

export const Container = styled.div`
    max-width: ${vars.grid.maxWidth};
    padding-left: ${vars.grid.gutter};
    padding-right: ${vars.grid.gutter};
    margin-left: auto;
    margin-right: auto;

    a {
        ${textLinkStyles}
    }

    ${getBreakpoint.print`
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
    `}
`

export const PageWrapper = styled.div`
    padding: 2rem 0;

    ${getBreakpoint.md`
        padding: 3.5rem 0;
    `}
`
