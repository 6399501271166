import { contract, mq } from './themeContract'
import { createTheme } from './createTheme'

export const gel2Color = {
    white: '#fff',
    black: '#000',
    grey: {
        dark: '#242934',
        shuttle: '#646974',
        geyser: '#dee3e5',
        mystic: '#ecf1f3',
    },
    ice: '#f4f7f9',
    solitude: '#f4f7f9',
    aquamentus: '#008A07',
    norwester: '#9aa8af',
    frostee: '#e6f0f3',
    shiraz: '#b81237',
    cherub: '#f4dbe1',
    tawny: '#dc5800',
    oldLace: '#f9f0e7',
    blue: {
        primary: '#002664',
        secondary: '#2e5299',
    },
    red: {
        primary: '#d7153a',
    },
    whcm: '#8FE6F1',
    UNSET: 'rgba(0, 255, 0, 0.7)',
}

export const gel2LightTheme = createTheme(contract, {
    name: 'gel-2-light',
    type: 'light',
    matches: [mq.light()],
    colors: {
        primary: {
            default: gel2Color.red.primary,
            hover: '#bf0c2a',
            active: '#C03D4B',
            disabled: '#DEE3E5',
            disabledText: '#646974',
        },
        secondary: {
            default: '#F9FDFF',
            disabled: '#DEE3E5',
            hover: gel2Color.blue.secondary,
            active: '#5975AD',
            disabledText: '#646974',
            disabledBorder: '$colors.secondary.disabled',
        },
        tertiary: {
            text: gel2Color.blue.secondary,
            disabledText: '#646974',
            off: gel2Color.grey.geyser,
            underlines: 'underline',
        },
        bg: {
            default: gel2Color.white,
            hover: gel2Color.grey.shuttle,
            active: gel2Color.grey.shuttle,
            text: gel2Color.grey.dark,
            off: gel2Color.grey.geyser,
            border: gel2Color.grey.geyser,
        },
        forms: {
            helpText: gel2Color.grey.shuttle,
            border: gel2Color.grey.shuttle,
        },
        bgAccent: {
            default: gel2Color.ice,
            text: gel2Color.grey.dark,
            low: gel2Color.grey.mystic,
        },
        link: {
            text: gel2Color.blue.secondary,
        },

        /* below are the GEL2 primary colors.
        It is still being used in some other places,
        but ideally it should be all gone as we iterate to GEL3 color tokens */

        /** brand red – snsw red */
        accent1: {
            default: gel2Color.red.primary,
            text: gel2Color.white,
            off: gel2Color.UNSET,
        },

        /** primary blue */
        accent2: {
            default: gel2Color.blue.primary,
            text: gel2Color.white,
        },

        /** secondary blue */
        accent3: {
            default: gel2Color.blue.secondary,
            text: gel2Color.white,
            off: gel2Color.grey.geyser,
        },

        error: {
            default: gel2Color.shiraz,
            off: gel2Color.cherub,
        },
        success: {
            default: gel2Color.aquamentus,
        },
        warning: {
            default: gel2Color.tawny,
            off: gel2Color.oldLace,
        },
        info: {
            default: gel2Color.blue.secondary,
        },
        disabled: {
            default: gel2Color.grey.geyser,
            text: gel2Color.grey.shuttle,
            off: gel2Color.grey.geyser,
        },
        selected: {
            default: gel2Color.blue.secondary,
            text: gel2Color.white,
            off: gel2Color.grey.geyser,
        },
        whcm: {
            default: gel2Color.whcm,
        },
    },
    radius: {
        regular: 8,
    },
    icon: {
        size: 16,
    },
})
