import React, {
    ComponentPropsWithoutRef,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react'
import {
    AriaDescribedBy,
    VoiceOverString,
    WithElementProps,
} from '@snsw-gel/types'
import { combineAriaDescribedBy } from '@snsw-gel/accessibility'

type Falsy = false | null | undefined
type CoercedToBoolean = any

export interface FieldHelpMessageProps {
    helpMessage?: VoiceOverString | Falsy
}

export interface FieldErrorMessageProps {
    errorMessage?: VoiceOverString | Falsy
    hasError?: CoercedToBoolean
}

export interface FieldLabelProps {
    label: VoiceOverString
}

// For Checkbox, CheckboxList and RadioList
export interface FieldItemLabelProps {
    /**
     * The 'label: React.ReactNode' is deprecated.
     * Use 'label: VoiceOverString' instead for accessibility.
     */
    label: VoiceOverString | React.ReactNode
}

export interface FieldRequiredProps {
    /** `aria-required: true` on form element */
    isRequired?: any
    /** `aria-required: false` on form element */
    isOptional?: any
}

export interface FieldProps {
    id?: string
}

export interface MarginProps {
    margin?: {
        top?:
            | number
            | 'none'
            | 'xs'
            | 'sm'
            | 'md'
            | 'lg'
            | 'xl'
            | 'xxl'
            | 'xxxl'
    }
}

export interface AllFieldProps
    extends FieldProps,
        FieldHelpMessageProps,
        FieldErrorMessageProps,
        FieldLabelProps,
        MarginProps,
        FieldRequiredProps,
        AriaDescribedBy {}

export interface FieldElementProps
    extends WithElementProps<ComponentPropsWithoutRef<'div'>, AllFieldProps> {}

export const FieldPropContext =
    React.createContext<Partial<AllFieldProps> | null>(null)

export function ProvideFieldProps(
    props: PropsWithChildren<{ provideProps: Partial<AllFieldProps> }>,
) {
    const { children, provideProps } = props

    const propsToProvide = useMemo(
        () => provideProps,
        [
            provideProps.id,
            provideProps.hasError,
            provideProps.errorMessage,
            provideProps.helpMessage,
            provideProps.isRequired,
            provideProps.isOptional,
            provideProps.label,
        ],
    )

    return (
        <FieldPropContext.Provider value={propsToProvide}>
            {children}
        </FieldPropContext.Provider>
    )
}

export function useProvidedFieldProps<
    T extends { ['aria-describedby']?: string; hasError?: boolean },
>(props?: T, opts?: { includeParents?: boolean }) {
    const includeParents = opts?.includeParents ?? true

    const parentFieldProps = useContext(FieldPropContext)

    const innerProps = {
        ...(includeParents && parentFieldProps),
        ...props,
    } as Omit<AllFieldProps, keyof T> & T

    if (props?.['aria-describedby'] || parentFieldProps?.['aria-describedby']) {
        innerProps['aria-describedby'] = combineAriaDescribedBy(
            props?.['aria-describedby'],
            parentFieldProps?.['aria-describedby'],
        )
    }

    if (props?.hasError || parentFieldProps?.hasError) {
        innerProps.hasError = true
    }

    return innerProps
}
