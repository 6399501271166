import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer } from './Dimmer'
import { Loader } from './Loader'
import { SROnly } from '@snsw-gel/accessibility'

export const ComponentLoader = ({
    className,
    active = true,
    label = 'Loading...',
    fullPage = false,
}) => {
    return (
        <Dimmer className={className} active={active} fullPage={fullPage}>
            <SROnly role='status'>{label}</SROnly>
            <Loader content={label} fullPage={fullPage} />
        </Dimmer>
    )
}

ComponentLoader.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.string,
    fullPage: PropTypes.bool,
}
