import React, { PropsWithChildren, Ref } from 'react'
import { forwarded, useId } from '@snsw-gel/utils'
import { IconNotificationError } from '@snsw-gel/icons'
import {
    FieldWrapper,
    FieldLabel,
    FieldHelp,
    FieldErrorWrapper,
} from './Field.styled'
import {
    ProvideFieldProps,
    FieldElementProps,
    useProvidedFieldProps,
} from './FieldProps'
import { clsFlags } from '@snsw-gel/theming'
import classNames from 'classnames'
import { combineAriaDescribedBy } from '@snsw-gel/accessibility'

export const FieldError = (
    props: PropsWithChildren<{ id?: string; className?: string }>,
) => {
    const { children, className, ...rest } = props
    const cls = classNames('field-error', className)
    return (
        <FieldErrorWrapper {...rest} className={cls}>
            <IconNotificationError />
            <span>{children}</span>
        </FieldErrorWrapper>
    )
}

/**
 * Field handles rendering label, help message, error message and children and provides accessibility props using the useLabelProps hook.
 */
function Field(props: FieldElementProps, ref: Ref<HTMLDivElement>) {
    const {
        children,
        className,
        margin,
        hasError,
        errorMessage,
        helpMessage,
        isOptional,
        isRequired,
        id,
        label,
        ['aria-describedby']: ariaDescribedBy,
        ...rest
    } = useProvidedFieldProps(props)

    const elemId = useId(id)

    const errorMessageId = `${elemId}-error`
    const helpMessageId = `${elemId}-helper`

    const showError = hasError && !!errorMessage

    const optional = isOptional && !isRequired
    const required = !isOptional && isRequired

    const showRequired =
        ((!optional || !required) && null) ||
        (required ? true : null) ||
        (optional ? false : null)

    const childNodes = React.Children.map(children, child =>
        React.isValidElement(child)
            ? React.cloneElement(child as any, {
                  'id': elemId,
                  'hasError': showError,
                  'aria-describedby': combineAriaDescribedBy(
                      ariaDescribedBy,
                      hasError && errorMessage ? errorMessageId : undefined,
                      helpMessage ? helpMessageId : undefined,
                      child.props['aria-describedby'],
                  ),
                  'aria-required': showRequired,
                  label,
              })
            : child,
    )

    const cls = classNames('field-item', className, hasError && clsFlags.error)

    return (
        <ProvideFieldProps
            provideProps={{
                id,
                hasError,
                errorMessage,
                helpMessage,
                isOptional,
                isRequired,
                label,
            }}
        >
            <FieldWrapper className={cls} margin={margin} ref={ref} {...rest}>
                <FieldLabel htmlFor={elemId}>
                    {optional ? `${label} (optional)` : label}
                </FieldLabel>
                {helpMessage && (
                    <FieldHelp id={helpMessageId}>{helpMessage}</FieldHelp>
                )}
                {childNodes}
                {showError && (
                    <FieldError id={errorMessageId}>{errorMessage}</FieldError>
                )}
            </FieldWrapper>
        </ProvideFieldProps>
    )
}

const _Field = forwarded(Field)
export { _Field as Field }
