import { createThemeContract } from './createTheme'
import { pxToRem } from '@snsw-gel/utils'

const gel3ColorValue = {
    black: '#000000',
    grey01: '#22272B',
    grey02: '#495054',
    grey03: '#CDD3D6',
    grey04: '#EBEBEB',
    offWhite: '#f2f2f2',
    white: '#ffffff',

    green01: '#004000',
    green02: '#00AA45',
    green03: '#A8EDB3',
    green04: '#DBFADF',

    teal01: '#0B3F47',
    teal02: '#2E808E',
    teal03: '#8CDBE5',
    teal04: '#D1EEEA',

    blue01: '#002664',
    blue02: '#146CFD',
    blue03: '#8CE0FF',
    blue04: '#CBEDFD',

    purple01: '#441170',
    purple02: '#8055F1',
    purple03: '#CEBFFF',
    purple04: '#E6E1FD',

    fuchsia01: '#65004D',
    fuchsia02: '#D912AE',
    fuchsia03: '#F4B5E6',
    fuchsia04: '#FDDEF2',

    red01: '#630019',
    red02: '#D7153A',
    red03: '#FFB8C1',
    red04: '#FFE6EA',

    orange01: '#941B00',
    orange02: '#F3631B',
    orange03: '#FFCE99',
    orange04: '#FDEDDF',

    yellow01: '#694800',
    yellow02: '#FAAF05',
    yellow03: '#FDE79A',
    yellow04: '#FFF4CF',

    brown01: '#523719',
    brown02: '#B68D5D',
    brown03: '#E8D0B5',
    brown04: '#EDE3D7',

    status: {
        infoBlue: {
            default: '#2E5299',
            background: '#EAEDF4',
            dark: '#223D72',
        },
        successGreen: {
            default: '#008A07',
            background: '#E5F6E6',
            dark: '#005704',
        },
        warningOrange: {
            default: '#C95000',
            background: '#FBEEE5',
            dark: '#943B00',
        },
        errorRed: {
            default: '#B81237',
            background: '#F7E7EB',
            dark: '#8B0E2A',
        },
    },
}

const initBreakpoint = {
    min: 0,
    max: 413,
}
const xsBreakpoint = {
    min: 414,
    max: 479,
}
const smBreakpoint = {
    min: 480,
    max: 767,
}
const mdBreakpoint = {
    min: 768,
    max: 1023,
}
const lgBreakpoint = {
    min: 1024,
    max: 1223,
}
const xlBreakpoint = {
    min: 1224,
}

export const contract = createThemeContract({
    breakpoints: {
        'initial': initBreakpoint,
        'smMobile': xsBreakpoint,
        'lgMobile': smBreakpoint,
        'tablet': mdBreakpoint,
        'desktop': lgBreakpoint,
        '4k': xlBreakpoint,
    },
    theme: {
        grid: {
            gutter: {
                '@initial': 20,
                '@tablet': 30,
            },
            maxWidth: 1224,
        },
        font: {
            body: '"Gotham", Arial, sans-serif',
            title: '$font.body',
            weight: {
                book: '300',
                default: '300',
                medium: '500',
                bold: '700',
            },
            size: {
                xs: 14,
                base: 16,
                sm: 18,
                md: 20,
                lg: 22,
                xl: 26,
                xxl: 28,
                xxxl: 32,
                xxxxl: 36,
            },
            lineHeight: {
                default: '1.5',
                heading: '1.4',
            },
        },
        typeSize: {
            sm: '$font.size.base',
            md: '$font.size.md',
            lg: {
                '@initial': '$font.size.md',
                '@tablet': '$font.size.lg',
            },
            xl: {
                '@initial': '$font.size.md',
                '@tablet': '$font.size.lg',
            },
            xxl: {
                '@initial': '$font.size.xxxl',
                '@tablet': '$font.size.xxxxl',
            },
        },
        transitions: {
            idle: 'calc(0.4s * $transitions.multiplier)',
            hover: 'calc(0.2s * $transitions.multiplier)',
            active: 'calc(0.1s * $transitions.multiplier)',
            delay: '0.15s',
            multiplier: '1',
        },
        layouts: {
            inputWidths: {
                xxs: pxToRem(56),
                xs: pxToRem(92),
                sm: pxToRem(132),
                md: pxToRem(200),
                lg: pxToRem(268),
                xl: pxToRem(416),
            },
        },
        easings: {
            natural: 'cubic-bezier(.19,1,.22,1)',
        },

        colors: {
            /** bg – the background colour */
            bg: {
                default: '$colors.background.default',
                hover: '',
                active: '',
                text: '',
                off: '',
                border: '',
            },
            forms: {
                bg: '$colors.bg.default',
                text: '$colors.bg.text',
                error: '$colors.error.default',
                labelText: '$colors.bg.border',

                helpText: '',
                border: '',
            },
            bgAccent: {
                default: '',
                text: '',
                low: '',
            },

            white: gel3ColorValue.white,
            brand: {
                dark: {
                    default: gel3ColorValue.blue01,
                    hover: '#2E4677',
                    active: gel3ColorValue.blue01,
                    disabled: '#A1ACC2',
                },
                light: {
                    default: gel3ColorValue.blue04,
                },
                supplementary: gel3ColorValue.blue02,
                accent: gel3ColorValue.red02,
                heroBusiness: gel3ColorValue.blue02,
                heroPersonal: gel3ColorValue.red02,
            },
            text: {
                default: gel3ColorValue.grey01,
                subtle: gel3ColorValue.grey02,
                reversed: gel3ColorValue.white,
                error: gel3ColorValue.status.errorRed.default,
                link: gel3ColorValue.blue01,
                linkReversed: gel3ColorValue.blue04,
                linkVisited: '#551A8B',
            },
            neutral: {
                grey100: gel3ColorValue.grey01,
                grey200: gel3ColorValue.grey02,
                grey300: gel3ColorValue.grey03,
                grey400: gel3ColorValue.grey04,
                grey500: gel3ColorValue.offWhite,
            },
            status: {
                info: gel3ColorValue.status.infoBlue.default,
                success: gel3ColorValue.status.successGreen.default,
                warning: gel3ColorValue.status.warningOrange.default,
                error: {
                    default: gel3ColorValue.status.errorRed.default,
                    hover: '#A14C56',
                    disabled: '#E4A5B3',
                    loading: '#D7153A',
                },
            },
            border: {
                default: gel3ColorValue.grey02,
                subtle: gel3ColorValue.grey04,
                median: gel3ColorValue.grey03,
                brand: gel3ColorValue.blue01,
                focus: '#0085B3',
                error: '$colors.status.error.default',
                errorSubtle: '$colors.white',
            },
            background: {
                default: gel3ColorValue.white,
                colourSurfaceSubtle: gel3ColorValue.offWhite,
                colourSurfaceLevel1: gel3ColorValue.white,
                linkHover: gel3ColorValue.blue01,
                status: {
                    info: gel3ColorValue.status.infoBlue.background,
                    success: gel3ColorValue.status.successGreen.background,
                    warning: gel3ColorValue.status.warningOrange.background,
                    error: gel3ColorValue.status.errorRed.background,
                },
            },

            // Actual button component instance to reduce mental cost if changes are needed
            // reference principles:
            // 1: reference from colorToken if possible;
            // 2: if not, add HEX value;
            // 3: reference from used "$colors.{token}" if possible.
            primary: {
                default: '$colors.brand.dark.default',
                hover: '$colors.brand.dark.hover',
                active: '$colors.brand.dark.default',
                disabled: '$colors.brand.dark.disabled',
                text: '$colors.white',
                hoverText: '$colors.primary.text',
                activeText: '$colors.primary.text',
                disabledText: '$colors.primary.text',
                border: '$colors.brand.dark.default',
            },

            secondary: {
                default: '$colors.background.default',
                hover: '$colors.brand.dark.default',
                active: '#2E4677',
                disabled: '$colors.secondary.default',
                text: '$colors.secondary.hover',
                hoverText: '$colors.secondary.default',
                activeText: '$colors.secondary.default',
                disabledText: '$colors.brand.dark.disabled',
                border: '$colors.secondary.hover',
                disabledBorder: '$colors.secondary.disabledText',
            },

            tertiary: {
                default: '$colors.white',
                hover: '$colors.brand.dark.hover',
                active: '$colors.brand.dark.default',
                disabled: '',
                text: '$colors.brand.dark.default',
                hoverText: '$colors.tertiary.default',
                activeText: '$colors.tertiary.default',
                disabledText: '$colors.brand.dark.disabled',
                off: '',
                underlines: 'none',
            },

            destructive: {
                default: '$colors.status.error.default',
                hover: '$colors.status.error.hover',
                active: '$colors.destructive.default',
                disabled: '$colors.status.error.disabled',
                text: '$colors.white',
                disabledText: '$colors.destructive.text',
            },

            link: {
                default: '',
                text: '#002664',
            },

            /* below are the GEL2 primary colors.
            It is still being used in some other places,
            but ideally it should be all gone as we iterate to GEL3 color tokens */

            /** brand red – snsw red */
            accent1: {
                default: '',
                hover: '#bf0c2a',
                active: '#C03D4B',
                text: '',
                off: '',
                hoverText: '$colors.accent1.text',
                activeText: '$colors.accent1.text',
                border: '$colors.accent1.default',
            },

            /** primary blue */
            accent2: {
                default: '',
                hover: '#2e5299',
                active: '#2e5299',
                text: '',
                off: '$colors.accent3.default',
                hoverText: '$colors.accent2.text',
                activeText: '$colors.accent2.text',
                border: '$colors.accent2.default',
            },

            /** secondary blue */
            accent3: {
                default: '',
                bg: 'rgb(249, 253, 255)',
                hover: '$colors.accent3.default',
                active: '#5975AD',
                text: '',
                off: '',
                hoverText: '$colors.accent3.text',
                activeText: '$colors.accent3.text',
                border: '$colors.accent3.default',
            },

            focus: '$colors.accent3.default',
            error: {
                default: '',
                off: '',
                text: '$colors.bg.default',
            },
            success: {
                default: '',
                off: '#e5f6e6',
            },
            warning: {
                default: '',
                off: '',
            },
            info: {
                default: '',
                off: '#eaedf4',
            },
            disabled: {
                default: '',
                text: '',
                off: '',
            },
            selected: {
                default: '',
                text: '',
                off: '',
            },

            whcm: {
                default: '',
            },
        },
        spacing: {
            none: pxToRem(0),
            xs: pxToRem(8),
            sm: pxToRem(16),
            md: pxToRem(24),
            lg: pxToRem(32),
            xl: pxToRem(40),
            xxl: pxToRem(48),
            xxxl: pxToRem(56),

            icons: {
                sm: pxToRem(16),
                md: pxToRem(24),
                lg: pxToRem(36),
                xl: pxToRem(48),
                xxl: pxToRem(60),
            },
        },
        radius: {
            none: 0,
            regular: 4,
            large: 6,
            pill: 999,
        },
        icon: {
            size: 16,
        },
    },
})

export const { vars, mq } = contract
