import { useEffect, useState } from 'react'

export function useMatchMedia(query: string) {
    const [match, setMatch] = useState<MediaQueryList | null>(null)
    const [matches, setMatches] = useState<boolean>(false)

    useEffect(() => {
        const cleanedQuery = query.replace(/^@media( +?)/, '')
        const match = window.matchMedia(cleanedQuery)
        setMatch(match)
        setMatches(match.matches)
        const listener = () => setMatches(match.matches)

        const on = () => {
            if (
                'addEventListener' in match &&
                typeof match.addEventListener === 'function'
            ) {
                match.addEventListener('change', listener)
                return () => {
                    match.removeEventListener('change', listener)
                }
            } else if (
                'addListener' in match &&
                typeof match.addListener === 'function'
            ) {
                // @ts-ignore
                match.addListener(listener)
                return () => {
                    // @ts-ignore
                    match.removeListener(listener)
                }
            }
        }

        return on()
    }, [query])

    return [matches, match] as const
}
