import React, { Key, ReactElement, isValidElement } from 'react'
import { AccordionItemChild } from '../Accordion'
import { AccordionItem, AccordionItemProps } from '../AccordionItem'

function isAccordionItemChild(
    child: unknown,
): child is ReactElement<AccordionItemProps, typeof AccordionItem> {
    return isValidElement(child) && child.type === AccordionItem
}

export function getAccordionItemKey(child: AccordionItemChild, index: number) {
    return String(child.key ?? child.props.id ?? index)
}

export function optionalIterableToSet<V extends string | number | bigint>(
    iterable?: Iterable<V>,
): Set<string> | undefined {
    if (iterable === undefined) {
        return undefined
    }

    const result = new Set<string>()

    for (const value of iterable) {
        result.add(String(value))
    }

    return result
}

export function useAccordionItemChildKeys(
    children: AccordionItemChild | AccordionItemChild[],
) {
    const items: string[] = []
    React.Children.forEach(children, (child: unknown, index: number) => {
        if (isAccordionItemChild(child)) {
            const key = getAccordionItemKey(child, index)
            items.push(key)
        }
    })
    return items
}

export function useAccordionDefaultOpenItems(
    defaultOpen: Iterable<Key> | undefined,
    children: AccordionItemChild | AccordionItemChild[],
) {
    if (defaultOpen !== undefined) {
        const result = new Set<string>()
        for (const key of defaultOpen) {
            result.add(String(key))
        }
        return result
    }

    const initialValue = new Set<string>()

    React.Children.forEach(children, (child: unknown, index: number) => {
        if (isAccordionItemChild(child)) {
            const key = getAccordionItemKey(child, index)
            if (child.props.expanded || child.props.defaultExpanded) {
                initialValue.add(key)
            }
        }
    })

    return initialValue
}
