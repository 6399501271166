import styled from 'styled-components'
import { getSpacing, getTypeSize, getBreakpoint } from '@snsw-gel/utils'

export const HeadingStyle = styled.h1`
    letter-spacing: 0;
    font-weight: 700;
    margin: 0;

    &.heading-1 {
        ${getTypeSize('xxl', 'heading')};
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('lg')};

        ${getBreakpoint.md`
            margin-top: ${getSpacing('xl')};
            margin-bottom: ${getSpacing('xxl')};
        `}
    }

    &.heading-2 {
        ${getTypeSize('xl', 'heading')};
        margin-top: ${getSpacing('lg')};
        margin-bottom: ${getSpacing('sm')};

        ${getBreakpoint.md`
            margin-top: ${getSpacing('xxxl')};
            margin-bottom: ${getSpacing('md')};
        `}
    }

    &.heading-3 {
        ${getTypeSize('lg', 'heading')};
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('sm')};

        ${getBreakpoint.md`
            margin-top: ${getSpacing('lg')};
            margin-bottom: ${getSpacing('md')};
        `}
    }

    &.heading-4 {
        ${getTypeSize('md', 'heading')};
        margin-top: ${getSpacing('sm')};
        margin-bottom: ${getSpacing('sm')};

        ${getBreakpoint.md`
            margin-top: ${getSpacing('md')};
            margin-bottom: ${getSpacing('md')};
        `}
    }

    &.heading-5 {
        ${getTypeSize('sm')};
        margin-top: ${getSpacing('sm')};
        margin-bottom: ${getSpacing('sm')};

        ${getBreakpoint.md`
            margin-top: ${getSpacing('md')};
            margin-bottom: ${getSpacing('md')};
        `}
    }

    &.heading-6 {
        ${getTypeSize('sm')};
        margin-top: ${getSpacing('sm')};
        margin-bottom: ${getSpacing('sm')};

        ${getBreakpoint.md`
            margin-top: ${getSpacing('md')};
            margin-bottom: ${getSpacing('md')};
        `}
    }
`
