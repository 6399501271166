import { useCallback, useRef } from 'react'
import { on } from '../dom/on'

export const useIgnoreScrollChange = (shouldIgnore = true) => {
    const cleanupRef = useRef<null | ((...args: any[]) => any)>(null)

    return useCallback(
        (element: HTMLInputElement) => {
            // eslint-disable-next-line
            cleanupRef.current?.()
            cleanupRef.current = null

            // dont run if we aren't given an element
            if (!element) {
                return
            }

            let tm: any

            const reset = () => {
                clearTimeout(tm)
                tm = setTimeout(() => {
                    element.style.pointerEvents = ''
                }, 300)
            }

            // Sets style.pointerEvents to "none" letting interactions fall through to the element below
            const onElScroll = on(
                element,
                ['wheel', 'scroll'],
                event => {
                    // cancel the first scroll event so the input doesn't change
                    event.preventDefault()

                    // pass scroll events through to the element below
                    element.style.pointerEvents = 'none'

                    reset()
                },
                { passive: false },
            )

            const onWindowScroll = on(window, 'scroll', reset)

            cleanupRef.current = () => {
                onElScroll()
                onWindowScroll()
                clearTimeout(tm)
            }
        },
        [shouldIgnore],
    )
}
