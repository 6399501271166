type GetEventMap<T> = T extends HTMLElement
    ? HTMLElementEventMap
    : T extends Element
    ? ElementEventMap
    : T extends Window
    ? WindowEventMap
    : T extends Document
    ? DocumentEventMap
    : T extends EventSource
    ? EventSourceEventMap
    : T extends MediaQueryList
    ? MediaQueryListEventMap
    : never

/*
Add an event listener to an element and returns a disposer for the event handler. Usefull for adding hooks in useEffects
eg
useEffect(() => on(window, 'resize', () => ...))
*/

const addEvents = ['addEventListener', 'removeEventListener'] as const
const addListener = ['addListener', 'removeListener'] as const
export function on<
    T extends
        | HTMLElement
        | Element
        | Window
        | Document
        | EventSource
        | MediaQueryList,
    EventMap = GetEventMap<T>,
    Key extends keyof EventMap = keyof EventMap,
>(
    element: T,
    event: Key | Key[],
    callback: (event: EventMap[Key] & { currentTarget: T }) => any | void,
    options?: any,
) {
    const methods = addEvents[0] in element ? addEvents : addListener
    const events = Array.isArray(event) ? event : [event]

    for (event of events) {
        if (methods === addEvents) {
            element[methods[0]](event as any, callback as any, options)
        } else {
            // @ts-ignore
            element[methods[0]](callback as any, options)
        }
    }

    return () => {
        for (event of events) {
            if (methods === addEvents) {
                element[methods[1]](event as any, callback as any, options)
            } else {
                // @ts-ignore
                element[methods[1]](callback as any, options)
            }
        }
    }
}

export function once<
    T extends
        | HTMLElement
        | Element
        | Window
        | Document
        | EventSource
        | MediaQueryList,
    EventMap = GetEventMap<T>,
    Key extends keyof EventMap = keyof EventMap,
>(
    element: T,
    events: Key | Key[],
    callback: (event: EventMap[Key] & { currentTarget: T }) => any | void,
    options?: any,
) {
    const off = on(
        element,
        events as any,
        e => {
            off()
            callback(e as any)
        },
        options,
    )
    return off
}
