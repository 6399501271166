export const colors = {
    brand: {
        snswPrimaryBlue: '#002664',
        snswPrimaryRed: '#d7153a',
        snswSecondaryBlue: '#2e5299',
        snswTertiaryBlue: '#0a7cb9',
        snswDarkGrey: '#242934',
    },
    white: '#ffffff',
    black: '#000000',
    grey: {
        darkGrey: '#242934',
        shuttle: '#646974',
        geyser: '#dee3e5',
        mystic: '#ecf1f3',
        iceBlue: '#f4f7f9',
    },
    solitude: '#eaedf4',
    aquamentus: '#008A07',
    norwester: '#9aa8af',
    frostee: '#e5f6e6',
    shiraz: '#b81237',
    cherub: '#f4dbe1',
    tawny: '#dc5800',
    oldLace: '#fcefe6',
    legacy: {
        white: '#ffffff',
        black: '#000000',
        red: '#d7153a',
        blue: '#2e5299',
        darkBlue: '#002664',
        blackBlue: '#242934',
        darkGrey: '#646974',
        lightGrey1: '#f4f7f9',
        lightGrey2: '#eaedf4',
        lightGrey3: '#9aa8af',
        lightGrey4: '#dee3e5',
        greyBackground: '#f4f7f9',
        green: '#008A07',
    },
    backgrounds: {
        error: '#f4dbe1',
        success: '#e5f6e6',
        warning: '#fcefe6',
        info: '#eaedf4',
        disabled: '#f4f7f9',
        selected: '#f4f7f9',
    },
    borders: {
        error: '#b81237',
        success: '#008A07',
        warning: '#dc5800',
        info: '#2e5299',
        disabled: '#646974',
        focus: '#2e5299',
    },
    text: {
        body: '#242934',
        link: '#2e5299',
        disabled: '#646974',
        error: '#b81237',
    },
    forms: {
        background: '#ffffff',
        borderColor: '#646974',
        labelText: '#242934',
        helpText: '#646974',
        inputText: '#242934',
    },
    breadcrumb: {
        link: '#242934',
    },
    modal: {
        background: '#ffffff',
        footerBorder: '#dee3e5',
    },
    buttons: {
        primaryButton: {
            text: '#ffffff',
            background: '#d7153a',
            backgroundHover: '#bf0c2a',
        },
        secondaryButton: {
            text: '#2e5299',
            background: '#f9fdff',
            backgroundHover: '#2e5299',
            backgroundActive: '#5975AD',
            border: '#2e5299',
        },
        tertiaryButton: {
            text: '#2e5299',
            background: '#2e5299',
            backgroundHover: '#2e5299',
            backgroundActive: '#5975AD',
            border: '#2e5299',
        },
        linkButton: {
            text: '#2e5299',
            backgroundHover: '#2e5299',
            backgroundActive: '#5975AD',
        },
        disabled: {
            background: '#dee3e5',
        },
    },
    icons: {
        primaryBlue: '#002664',
        secondaryBlue: '#2e5299',
        darkGrey: '#242934',
        white: '#ffffff',
    },
    statusLabel: {
        success: '#008A07',
        warning: '#C95000',
    },
}
