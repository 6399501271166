import styled, { css } from 'styled-components'
import { tokens } from '@snsw-gel/tokens'
import {
    pxToRem,
    getSpacing,
    getBreakpoint,
    marginMixin,
} from '@snsw-gel/utils'
import { Fieldset } from '@snsw-gel/fieldset'
import { FieldLabel } from '@snsw-gel/field'
import { mq } from '@snsw-gel/theming'

const { colors, font } = tokens

export const HiddenRadioCheckbox = css`
    position: absolute;
    opacity: 0;
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    padding: 0;
    margin: 0;
`

export const RadioItemEditorContainer = styled.div`
    margin-top: ${pxToRem(8)};
    margin-bottom: -${pxToRem(8)};
    margin-left: ${pxToRem(11)};
    border-left: 8px solid #d8d8d8;
    padding: ${getSpacing([6, 'sm', 6, 'sm'])};

    .form__item label:before {
        display: none;
    }

    .form__item label {
        display: block;
        width: 100%;
        padding: ${getSpacing(['none', 'none', 12, 'none'])};
        max-width: ${pxToRem(769)};
    }

    .form__item input,
    select,
    textarea {
        opacity: 1;
        position: relative;
        width: 100%;
        max-width: ${pxToRem(372)};
        font-size: ${pxToRem(font.size.base)};
        color: ${colors.forms.inputText};
        background-color: ${colors.forms.background};
        border: 2px solid ${colors.forms.borderColor};
        border-radius: 6px;
        padding: ${getSpacing(['none', 13])};
        -webkit-transition: border-color 0.25s ease, background-color 0.25s ease;
        transition: border-color 0.25s ease, background-color 0.25s ease;
        height: ${pxToRem(48)};
        cursor: text;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        caret-color: ${colors.forms.labelText};
    }

    .form__item input.error,
    select.error,
    textarea.error {
        border-color: ${colors.borders.error};
        color: ${colors.forms.inputText};
        box-shadow: none;
    }
`

export const RadioItemClarifyContainer = styled.div`
    margin-left: ${pxToRem(48)};
    font-size: ${pxToRem(14)};
    color: ${colors.grey.shuttle};
`

export const RadioGroupFieldset = styled(Fieldset)`
    ${props => props.inline && RadioInlineStyles}
    ${marginMixin}

    ${props =>
        props.inline &&
        getBreakpoint.md`
    `}

    .fieldset__field-error {
        margin-top: ${pxToRem(24)};
    }

    &.radio-group__inline {
        .fieldset__field-error {
            margin-top: ${pxToRem(8)};

            ${mq.min('tablet')} {
                margin-top: ${pxToRem(24)};
            }
        }
    }
`

export const Radio = styled.div`
    + .field-error {
        margin-top: ${getSpacing(['md'])};
    }
`

export const RadioItem = styled.div.attrs(() => ({
    className: 'radio-item',
}))`
    position: relative;
    margin-top: ${getSpacing(['sm'])};
`

export const CustomRadioCheckbox = css`
    position: relative;
    padding: ${getSpacing([4, 'none', 4, 'xxl'])};
    margin: 0;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border: 2px solid ${colors.forms.borderColor};
        background-color: ${colors.forms.background};
        width: ${pxToRem(32)};
        height: ${pxToRem(32)};
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border: 2px solid transparent;
        background-color: ${colors.grey.shuttle};
        transform: scale(0.68);
        width: ${pxToRem(32)};
        height: ${pxToRem(32)};
        display: none;
    }
`

export const RadioLabel = styled(FieldLabel)`
    ${CustomRadioCheckbox}
    display: inline-block;

    &::before {
        border-radius: 1rem;
    }

    &::after {
        border-radius: calc(1rem + 3px);
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        &::after {
            -ms-high-contrast-adjust: none;
            forced-color-adjust: none;
            background-color: InactiveBorder;
        }
        &::before {
            border: 2px solid InactiveBorder;
        }
    }

    ${props =>
        props.hasError &&
        css`
            &::before {
                border-color: ${colors.borders.error};
                box-shadow: none;
            }
        `}
`

export const RadioInput = styled.input`
    ${HiddenRadioCheckbox}

    &:checked + ${RadioLabel} {
        &::after {
            display: block;
        }
    }

    &:focus ~ ${RadioLabel} {
        &::before {
            outline: 2px solid transparent;
            outline-offset: 1px;
        }
    }

    &:focus ~ ${RadioLabel} {
        &::before {
            box-shadow: 0 0 0 1px #fff, 0 0 0 3px ${colors.borders.focus};
        }
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        &:focus ~ ${RadioLabel} {
            &::before {
                -ms-high-contrast-adjust: none;
                forced-color-adjust: none;
                outline-color: Highlight;
                background-color: Background;
                box-shadow: none;
            }
        }
    }
`

export const RadioInlineStyles = css`
    ${Radio} {
        display: flex;
        padding-top: ${getSpacing('sm')};
    }

    ${RadioItem} {
        margin-right: ${getSpacing('sm')};
        margin-bottom: 0;
        margin-top: 0;
    }

    .radio-item:first-of-type {
        margin-top: 0;
        margin-left: 0;
    }

    ${RadioItemEditorContainer} {
        margin-top: 0;
        margin-bottom: 0;
        padding: ${getSpacing([4, 'sm', 'none', 'sm'])};
    }

    ${getBreakpoint.md`
        .fieldset__legend {
            margin: 0;
        }

        ${RadioItem} {
            margin: ${getSpacing(['none', 'none', 'none', 'md'])};
        }
    `}
`

export default Radio
