import React, { ReactNode, Ref, useRef, ComponentPropsWithoutRef } from 'react'
import {
    IconExternalLink,
    IconChevronLeft,
    IconArrowRight,
} from '@snsw-gel/icons'
import StyledButton from './Button.styled'
import { bindRefs, deprecation, forwarded, useId } from '@snsw-gel/utils'
import { WithElementProps } from '@snsw-gel/types'
import classNames from 'classnames'

export type ButtonThemes =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'destructive'
    | 'link'
    | 'arrow'
    | 'back'

export interface ButtonProps {
    children: ReactNode
    className?: string
    disabled?: boolean
    href?: string // HTML anchor link
    to?: string // React router link
    /**
     * The 'theme' prop is deprecated.
     * Please use 'variant' instead.
     * @deprecated
     */
    theme?: ButtonThemes

    /**
     * @default primary
     */
    variant?: ButtonThemes

    external?: boolean
    /**
     * If the button type is not specified, it will default to 'button'.
     * This prevents accidental form submits from happening when any button in the form is clicked
     * @default button
     *  */
    type?: 'button' | 'submit' | 'reset'
    /**
     * The 'size' prop is deprecated.
     * @deprecated
     * @default default
     *  */
    size?: 'default'

    /**
     * The as prop is used to pass in the Link component from [react router](https://reactrouter.com/en/6.16.0/components/link).
     */
    as?: string | React.ComponentType<any>
    iconEnd?: ReactNode
    iconStart?: ReactNode

    /**
     *	When the button is clicked the `onClick` callback is called. Interesting
     */
    onClick?: ComponentPropsWithoutRef<'button'>['onClick']
}

export interface ButtonWithElementProps
    extends WithElementProps<ComponentPropsWithoutRef<'button'>, ButtonProps> {}

function getVariantClassName(variant: ButtonThemes) {
    switch (variant) {
        case 'arrow':
        case 'back':
            return `button--link button--${variant}`
        default:
            return `button--${variant}`
    }
}

function getSizeClassName(size: string) {
    if (!size || size === 'default') {
        return ''
    }
    return `button--size-${size}`
}

export const Button = forwarded(
    (
        props: ButtonWithElementProps,
        ref?: Ref<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
        /* eslint-disable prefer-const */
        const {
            theme = 'primary',
            variant = theme || 'primary',
            size = 'default',
            disabled = false,
            external = false,
            type = 'button',
            children = variant === 'back' ? 'Back' : '',
            onClick,
            className,
            as: Component,
            id,
            ...rest
        } = props

        const cls = classNames(
            className,
            getVariantClassName(variant),
            getSizeClassName(size),
        )

        const elemId = useId(id)

        const innerRef = useRef<HTMLButtonElement>(null)

        deprecation('theme' in props, `Button.theme`)
        deprecation('size' in props, `Button.size`)

        let iconStart: ReactNode | undefined = props.iconStart
        let iconEnd: ReactNode | undefined = props.iconEnd

        const hideExternalIcon = iconStart || iconEnd
        /*
        If we have iconStart or iconEnd we don't want to show the external icon
        */
        let iconEndClassName = 'iconEnd'

        if (iconStart === undefined) {
            if (variant === 'back') {
                iconStart = <IconChevronLeft />
            }
        }

        if (iconEnd === undefined) {
            if (
                external &&
                !hideExternalIcon &&
                variant !== 'back' &&
                variant !== 'arrow' &&
                typeof Component !== 'object'
            ) {
                iconEndClassName = 'iconExt'
                iconEnd = <IconExternalLink title='Opens in a new tab' />
            } else if (variant === 'arrow') {
                iconEnd = <IconArrowRight />
            }
        }

        const extraProps: any = {}

        if (typeof Component !== 'object' && props.href) {
            extraProps.href = props.href
            extraProps.as = !props.disabled ? 'a' : 'button'
            extraProps.type = null

            if (external) {
                extraProps.external = external ? 1 : 0
                extraProps.target = '_blank'
                extraProps.rel = 'noopener noreferrer'
            }
        } else if (typeof Component === 'object' && (props.href || rest.to)) {
            extraProps.to = props.href || rest.to
            extraProps.as = props.as
            extraProps.type = null
        } else {
            extraProps.type = type
            extraProps.disabled = disabled
            extraProps.external = external ? 1 : 0
        }

        return (
            <StyledButton
                id={elemId}
                className={cls}
                onClick={onClick}
                ref={bindRefs(innerRef, ref)}
                as={Component}
                disabled={disabled}
                type={type}
                {...extraProps}
                {...rest}
            >
                {iconStart && (
                    <span className='iconStart iconFlex'>{iconStart}</span>
                )}
                {children}
                {iconEnd && (
                    <span className={classNames('iconFlex', iconEndClassName)}>
                        {iconEnd}
                    </span>
                )}
            </StyledButton>
        )
    },
)

// @ts-ignore
Button.displayName = 'Button'
