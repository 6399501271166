const colors = {
    white: '#ffffff',
    black: '#000000',
    red: '#d7153a',
    blue: '#2e5299',
    darkBlue: '#002664',
    blackBlue: '#242934',
    darkGrey: '#646974',
    lightGrey1: '#f4f7f9',
    lightGrey2: '#eaedf4',
    lightGrey3: '#9aa8af',
    lightGrey4: '#dee3e5',
    greyBackground: '#f4f7f9',
    green: '#008A07',
}
const media = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
}
const breakpoints = ['480px', '576px', '768px', '992px', '1200px', '1600px']

// aliases
breakpoints.xs = breakpoints[0] //480px
breakpoints.sm = breakpoints[1] //576px
breakpoints.md = breakpoints[2] //768px
breakpoints.lg = breakpoints[3] //992px
breakpoints.xl = breakpoints[4] //1200px
breakpoints.xxl = breakpoints[5] //1600px

const space = [0, 8, 16, 24, 32, 40, 48, 56, 62, 70, 78, 86]
space['0.5'] = space[1]
space['1'] = space[2]
space['1.5'] = space[3]
space['2'] = space[4]
space['2.5'] = space[5]
space['3'] = space[6]
space['3.5'] = space[7]
space['4'] = space[8]
space['4.5'] = space[9]
space['5'] = space[10]
space['5.5'] = space[11]

/**
 * The styled-components theme
 */
export const theme = {
    colors,
    space: space,
    media,
    breakpoints: breakpoints,
    componentWidths: {
        screener: {
            full: '54rem',
            progressBar: '54rem',
            question: '42rem',
            options: '42rem',
            footer: {
                full: '100vw',
                content: '42rem',
            },
        },
    },
    pageWidths: {
        lpBanner: '97.5rem',
        pageMax: '76.5rem',
        copyBlock: '55.625rem',
    },
    name: 'ETDB',
}
