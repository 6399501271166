import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconCalendarProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconCalendar = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconCalendarProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M15 16H1a1 1 0 01-1-1V3a1 1 0 011-1h14a1 1 0 011 1v12a1 1 0 01-1 1zM2 14h12V4H2z' />
            <path d='M5 6a1 1 0 01-1-1V1a1 1 0 012 0v4a1 1 0 01-1 1zm6 0a1 1 0 01-1-1V1a1 1 0 012 0v4a1 1 0 01-1 1z' />
        </svg>
    )
}
