import { CSSProperties } from 'react'
export type RecursiveVal<T> = { [k: string]: RecursiveVal<T> | T }

type Prefix<Pref extends string, T> = T extends string ? `${Pref}${T}` : never

export type ObjToVars<Dict extends {}> = Omit<
    {
        [K in keyof Dict]: Dict[K] extends Function
            ? VarSetter
            : Dict[K] extends VarSetter
            ? VarSetter
            : keyof Dict[K] extends Prefix<'@', string>
            ? VarSetter
            : Dict[K] extends { [k: string]: any }
            ? ObjToVars<Dict[K]>
            : VarSetter
    },
    'default'
> &
    (Dict extends { default: any }
        ? (() => string) & { toString(): string; default: VarSetter }
        : {})

export type ThemeContractConfig<
    Breakpoints extends BreakpointsDict = {},
    Colors extends ColorDict = {},
    Spacing extends SpacingDict = {},
    Fonts extends {} = {},
> = {
    theme: {
        colors: Colors
        spacing: Spacing
        font: Fonts
        radius: {}
        icon: {}
        transitions: {}
        easings: {}
        layouts: {}
        typeSize: {}
        grid: {
            gutter: ResponsiveValue<Breakpoints, number>
            maxWidth: ResponsiveValue<Breakpoints, number>
        }
    }
    breakpoints: Breakpoints
} & { [k: string]: any }

type MediaQueryApi<Breakpoints extends BreakpointsDict> = {
    min: (breakpoint: keyof Breakpoints | number) => string
    max: (breakpoint: keyof Breakpoints | number) => string
    minmax: (
        min: keyof Breakpoints | number,
        max: keyof Breakpoints | number,
    ) => string
    (obj: {
        [k in Prefix<'@', keyof Breakpoints>]?: string | number | VarSetter
    }): {
        [k: string]: string | number | VarSetter
    }
    print: () => string
    dark: () => string
    light: () => string
}

export interface ThemeContract<Config extends ThemeContractConfig> {
    'config': Config
    'theme': ObjToVars<Config['theme']>
    'breakpoints': Config['breakpoints']
    'breakpoints[]': (Breakpoint & { name: keyof Config['breakpoints'] })[]
    'mq': MediaQueryApi<Config['breakpoints']>
    'vars': ObjToVars<Config['theme']>
}

export interface Theme<
    Colors extends {} = {},
    Palettes extends {} = {},
    Spacing extends {} = {},
> {
    colors: ObjToVars<Colors>
    palettes: ObjToVars<Palettes>
    spacing: ObjToVars<Spacing>
}

export type ColorDict = {
    [k: string]: VarSetter | CSSProperties['color'] | ColorDict
}
export type SpacingDict = { [k: string]: number | string }
export type Breakpoint = {
    min?: number
    max?: number
}
export type BreakpointsDict = {
    [k: string]: Breakpoint
}

export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
        ? Array<DeepPartial<U>>
        : T[P] extends ReadonlyArray<infer U>
        ? ReadonlyArray<DeepPartial<U>>
        : DeepPartial<T[P]>
}

export type ResponsiveObj<Breakpoints extends BreakpointsDict, T> = {
    [_k in keyof Breakpoints]?: T
}
export type ResponsiveValue<Breakpoints extends BreakpointsDict, T> =
    | T
    | ResponsiveObj<Breakpoints, T>

function getValueAsString(next: string | number | { toString(): string }) {
    switch (typeof next) {
        case 'string':
        case 'number':
            return next
        case 'object':
            return next.toString()
        default:
            throw new Error(`Invalid type for var setter: ${typeof next}`)
    }
}

export class VarSetter {
    static $$type = '$VarSetter'
    static counter = 0;
    [k: string]: any
    key: string
    id = VarSetter.counter++
    var: string
    defaultVal?: string
    constructor(hint: string | number, defaultValue?: string) {
        // @ts-ignore - we're using a symbol as a key here so it is not enumerable
        this[VarSetter.$$type] = true
        this.key = hint ? `${hint}-${this.id}` : `var-${this.id}`
        this.var = `--${this.key}`
        this.defaultVal = defaultValue
    }
    [Symbol.toPrimitive]() {
        return this.toString()
    }
    [Symbol.toStringTag] = VarSetter.$$type
    set(next: string | number | { toString(): string } | undefined): string {
        if (next === undefined) {
            return ''
        }
        return `${this.var}: ${getValueAsString(next)};`
    }
    setStyle(next: string | number | { toString(): string } | undefined) {
        if (next === undefined) {
            return {}
        }
        return {
            [this.var]: getValueAsString(next),
        }
    }
    toString() {
        if (this.defaultVal !== undefined) {
            return `var(${this.var}, ${getValueAsString(this.defaultVal)})`
        } else {
            return `var(${this.var})`
        }
    }
}

export function createVar(name: string | number, defaultValue?: string) {
    return new VarSetter(name, defaultValue)
}

export function isVarSetter(obj: any): obj is VarSetter {
    return typeof obj === 'object' && obj[VarSetter.$$type]
}

export function fallbackVar(cssVar: VarSetter, value: string | number) {
    return `var(${cssVar}, ${value})`
}
