import styled from 'styled-components'
import { Heading } from '@snsw-gel/content'
import { vars, mq } from '@snsw-gel/theming'
import { pxToRem, getSpacing } from '@snsw-gel/utils'

const StyledSectionHeader = styled.header`
    background: ${vars.colors.bgAccent};
    padding: ${getSpacing(['sm', 'none'])};

    ${mq.min('tablet')} {
        padding: ${getSpacing(['lg', 'none', 'md', 'none'])};
    }

    ${mq.print()} {
        padding: 0;
        background: 0;
    }

    h1 {
        &.heading-1 {
            margin: 0;
            max-width: ${pxToRem(768)};
        }
    }

    p {
        margin-top: ${getSpacing('xs')};
        margin-bottom: 0;
        max-width: ${pxToRem(768)};
    }

    .notice:last-of-type {
        margin-bottom: 0;
    }

    @media print {
        .container {
            border-bottom: 2px solid ${vars.colors.bg.border};
            padding: 0 0 25pt;
        }
    }

    .page-header__label {
        /* note :match PageLabel styles */
        font-size: ${pxToRem(14)};
        line-height: ${vars.font.lineHeight.heading};
    }
`

export const StyledFormHeader = styled(StyledSectionHeader)`
    padding: ${getSpacing(['sm', 'none', 'sm', 'none'])};

    ${mq.min('tablet')} {
        padding: ${getSpacing(['md', 'none', 'md', 'none'])};
    }

    h1 {
        &.heading-1 {
            margin-top: ${getSpacing('sm')};
            margin-bottom: 0;
            max-width: ${pxToRem(768)};

            ${mq.min('tablet')} {
                margin-top: ${getSpacing('md')};
            }
        }
    }

    p {
        margin: 0;
    }
`

export const PageNav = styled.div`
    margin-bottom: ${getSpacing('sm')};
`

export const PageLabel = styled.span`
    font-size: ${pxToRem(14)};
    line-height: ${vars.font.lineHeight.heading};
`

export const PageTitle = styled(Heading).attrs({ level: 1 })`
    + * {
        margin-top: ${getSpacing('sm')};
        margin-bottom: 0;

        ${mq.min('tablet')} {
            margin-top: ${getSpacing('xl')};
            margin-bottom: ${getSpacing('md')};
        }
    }
`

export default StyledSectionHeader
