import styled, { css } from 'styled-components'
import { tokens } from '@snsw-gel/tokens'
import { pxToRem, getSpacing } from '@snsw-gel/utils'
import { Spinner } from './Spinner.styled'

const { font } = tokens

export const LoaderContainer = styled.div`
    text-align: center;
    position: relative;
    max-width: ${pxToRem(500)};
`

export const SpinnerImageContainer = styled.div`
    text-indent: 100%;
    overflow: hidden;
    width: 100%;
    height: ${pxToRem(70)};

    ${Spinner} {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        left: 50%;
        width: ${pxToRem(70)};
        height: ${pxToRem(70)};
    }
`

export const LoaderMessage = styled.p`
    margin: ${getSpacing([10, 0, 0])};

    ${props =>
        props.fullPage &&
        css`
            font-size: ${pxToRem(font.size.xxl)};
            font-weight: ${font.weight.bold};
        `};
`
