declare global {
    interface Window {
        env: {
            NODE_ENV: string
        }
    }
}
export { GlobalStyle } from './GlobalStyles.styled'
export { createVar } from './themeTypes'
export {
    createThemeContract,
    createTheme,
    printThemesCss,
    getThemeStyles,
    getThemeCssContent,
    getThemeCss,
} from './createTheme'
export { vars, mq, contract } from './themeContract'
import { gel2LightTheme } from './gel2Theme'
import { gel3DarkTheme, gel3LightTheme } from './themes'

export { gel2LightTheme, gel3DarkTheme, gel3LightTheme }

export const gel2Themes = [gel2LightTheme]
export const gel3Themes = [gel3LightTheme, gel3DarkTheme]

export const clsFlags = {
    error: 'error',
} as const
