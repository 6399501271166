import { useLayoutEffect, useRef } from 'react'
import { heightToAnimate } from '../Accordion.styled'
import { once } from '@snsw-gel/utils'

export function useAnimatedAccordionContent({
    open,
    active = true,
}: {
    open: boolean
    active?: boolean
}) {
    const ref = useRef<HTMLDivElement>(null)
    const hasBeenExpanded = useRef<HTMLDivElement>()
    useLayoutEffect(() => {
        if (!ref.current || !active) {
            return
        }
        if (hasBeenExpanded.current !== ref.current) {
            hasBeenExpanded.current = ref.current
            return
        }
        ref.current.classList.add('is-animating')

        if (open) {
            ref.current.style.removeProperty('height')
        } else {
            ref.current.style.height = 'auto'
        }
        const { height } = ref.current.getBoundingClientRect()

        ref.current.style.height = open
            ? `calc(${height}px - ${heightToAnimate})`
            : `${heightToAnimate}`
        // forces layout to be recalculated
        ref.current.offsetLeft
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        ref.current.style.height = open ? `${height}px` : '0px'
        return once(ref.current, 'transitionend', () => {
            ref.current?.style.removeProperty('height')
        })
    }, [open, active])
    return ref
}
