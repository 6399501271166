import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconNotificationSuccessProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconNotificationSuccess = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconNotificationSuccessProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M7.06 12a1 1 0 00.72-.38l4-5a1 1 0 10-1.56-1.24L6.92 9.5 5.71 8.29a1.004 1.004 0 00-1.42 1.42l2 2A1 1 0 007 12h.06z'
                    fill='#FFF'
                />
                <path
                    d='M8 16A8 8 0 118 0a8 8 0 010 16zm-.94-4a1 1 0 00.72-.38l4-5a1 1 0 10-1.56-1.24L6.92 9.5 5.71 8.29a1.004 1.004 0 00-1.42 1.42l2 2A1 1 0 007 12h.06z'
                    fill='#008A07'
                />
            </g>
        </svg>
    )
}
