import React, { ForwardedRef, ReactElement, RefAttributes } from 'react'
import { forwarded } from '../react/forwarded'
import { DEPRECATIONS } from './all-deprecations'
import { storage } from './storage'

export const DeprecationActions = {
    Ignore: 'Ignore',
    Warn: 'Warn',
    Throw: 'Throw',
} as const

export type DeprecationActions =
    (typeof DeprecationActions)[keyof typeof DeprecationActions]

export function setDeprecationAction(state: DeprecationActions | undefined) {
    if (state === undefined) {
        storage.remove()
    } else {
        storage.set(String(state))
    }
}

const DEFAULT_DEPRECATION_ACTION = // @ts-ignore
    process.env.NODE_ENV === 'production'
        ? DeprecationActions.Ignore
        : DeprecationActions.Warn

function getDeprecationAction(): DeprecationActions {
    let value = storage.get() ?? null

    if (value && !(value in DeprecationActions)) {
        storage.remove()
        value = null
    }

    if (value === null || !(value in DeprecationActions)) {
        return DEFAULT_DEPRECATION_ACTION
    }

    return value as DeprecationActions
}

// Should let the DEPRECATIONS object disappear in production builds
const DEPS = // prettier-ignore
    // @ts-ignore
    process.env.NODE_ENV !== 'production'
        ? DEPRECATIONS
        : ({} as typeof DEPRECATIONS)

/**
 * Deprecation helper, logs a warning to the console if the condition is true
 * @param {any} condition condition to check for deprecation
 * @param {string} message message to display when deprecated
 * @param {Date} asOf date of deprecation
 */
export const deprecation = /* __PURE__ */ (
    condition: any,
    id: keyof typeof DEPRECATIONS,
) => {
    // @ts-ignore
    const DEV = process.env.NODE_ENV === 'development'

    const depAction = getDeprecationAction()
    if (depAction === DeprecationActions.Ignore) {
        return
    }

    const pathKey = (id.replace(/\./g, '-') + '--docs').toLowerCase()

    if (DEV && condition) {
        if (!(id in DEPS)) {
            throw new Error(`Deprecation [${id}] does not exist`)
        }
        const { from: asOf, message } = DEPS[id]
        const [year, month, day] = asOf
        const log = [
            `Deprecation ${id}: [${year}/${month}/${day}]:`,
            message,
            '',
            `More info at: https://kiama.testservicensw.net/storybook/?path=/docs/deprecations-${pathKey}`,
            `To help with deprecations set \`setDeprecationAction(DeprecationActions.Throw)\` in your app.`,
        ].join('\n')

        if (depAction === DeprecationActions.Throw) {
            throw new Error(log)
        } else {
            console.error(log)
        }
    }
    if (!DEV && condition) {
        const log = `Deprecation ${id}: More info at https://kiama.testservicensw.net/storybook/?path=/docs/deprecations-${pathKey}`
        if (depAction === DeprecationActions.Throw) {
            throw new Error(log)
        } else {
            console.error(log)
        }
    }
}

export function deprecatedComponent<T, P = {}>(
    deprecationId: keyof typeof DEPRECATIONS,
    Render: (props: P, ref: ForwardedRef<T>) => ReactElement | null,
): (props: P & RefAttributes<T>) => ReactElement | null {
    const Component = forwarded((props: any, ref: any) => {
        deprecation(true, deprecationId)
        if (typeof Render === 'function') {
            return Render(props, ref)
        } else {
            // @ts-ignore
            return <Render {...props} ref={ref} />
        }
    })

    // @ts-ignore
    Component.displayName = `Deprecated(${Render.displayName || Render.name})`

    return Component
}
