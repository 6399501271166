import { useRef } from 'react'
// @ts-ignore
import * as React from 'react'

/**
 * we have to use "useId".toString() here because otherwise old versions of webpack will read that and run the check at build time.
 * Which will break
 *
 * This was eventually fixed in later webpack versions but to avoid telling people to update we are just doing a bit of hackery here
 *  */
const useReactIdPoly =
    typeof (React as any)['useId'.toString()] === 'function'
        ? (React as any)['useId'.toString()]
        : () => undefined

let globalId = 0
export function useId(outerId?: string) {
    const idRef = useRef(outerId)
    const reactId = useReactIdPoly()

    if (
        outerId !== undefined &&
        reactId !== null &&
        // @ts-ignore
        reactId !== false
    ) {
        return outerId
    }

    if (reactId) {
        return reactId
    }

    if (!idRef.current) {
        idRef.current = `id-${++globalId}`
    }

    return idRef.current
}
