import styled, { css } from 'styled-components'
import { getBreakpoint, getSpacing, pxToRem } from '@snsw-gel/utils'
import { vars } from '@snsw-gel/theming'

export const ProgressStepperList = styled.ol`
    display: flex;
    flex-flow: row nowrap;
    padding-inline-start: 0;
    align-content: flex-start;
    margin-block-end: 0;
    margin-block-start: 0;
`

export const ProgressStepperStep = styled.li`
    display: flex;
    flex-flow: column wrap;
    position: relative;
    width: auto;
    flex: 16%;
    text-align: center;
    padding: 0;
    margin-bottom: 0;

    &:last-child {
        flex: 0;
    }

    ${getBreakpoint.md`
        align-content: center;
        padding: 0 12px;
        align-items: center;

        &:last-child {
            flex: 16%;
        }
    `}

    &:after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        right: 0;
        top: 14px;

        ${getBreakpoint.md`
            right: -50%;
        `}
    }

    /* previous positions */
    ${props =>
        props.status === 'completed' &&
        css`
            font-weight: ${vars.font.weight};
            color: ${vars.colors.accent2};

            svg {
                fill: ${vars.colors.accent2.text};
                @media (-ms-high-contrast: active), (forced-colors: active) {
                    forced-color-adjust: none;
                    background-color: black;
                    fill: ${vars.colors.whcm};
                }
            }

            &:after {
                background-color: ${vars.colors.accent2};

                @media (-ms-high-contrast: active), (forced-colors: active) {
                    forced-color-adjust: none;
                    background-color: ${vars.colors.whcm};
                }
            }
            div {
                background-color: ${vars.colors.accent2};
                border: 2px solid ${vars.colors.accent2};

                @media (-ms-high-contrast: active), (forced-colors: active) {
                    forced-color-adjust: none;
                    background-color: black;
                    border: 2px solid ${vars.colors.whcm};
                    color: ${vars.colors.whcm};
                }
            }
        `}

    /* next positions */
    ${props =>
        props.status === 'todo' &&
        css`
            font-weight: ${vars.font.weight};
            color: ${vars.colors.bg.active};
            &:after {
                background-color: ${vars.colors.bg.active};

                @media (-ms-high-contrast: active), (forced-colors: active) {
                    forced-color-adjust: none;
                    background-color: white;
                }
            }
            div {
                background-color: ${vars.colors.bg};
            }
        `}

    /* active position */
    ${props =>
        props.status === 'current' &&
        css`
            background-color: transparent;
            font-weight: ${vars.font.weight.bold};
            color: ${vars.colors.accent2};

            div {
                font-size: ${pxToRem(18)};
                padding-top: 0;
                background: ${vars.colors.accent2};
                border: 2px solid ${vars.colors.accent2};
                color: ${vars.colors.bg};

                @media (-ms-high-contrast: active), (forced-colors: active) {
                    forced-color-adjust: none;
                    background: black;
                    border: 2px solid white;
                }
            }
            &:after {
                background-color: ${vars.colors.bg.active};

                @media (-ms-high-contrast: active), (forced-colors: active) {
                    forced-color-adjust: none;
                    background-color: white;
                }
            }
        `}

    &:last-child {
        &:after {
            display: none;
        }
    }
`

export const StepPosition = styled.div`
    font-size: ${pxToRem(14)};
    border-radius: 50%;
    width: ${getSpacing('lg')};
    height: ${getSpacing('lg')};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    text-align: center;
    background: ${vars.colors.bg};
    border: 2px solid currentColor;
    color: ${vars.colors.bg.active};
    z-index: 1;
    svg {
        background-color: ${vars.colors.accent2};
    }
`

export const MobileStepLabel = styled.span`
    font-size: ${vars.font.size.base};
    font-weight: ${vars.font.weight.bold};
    color: ${vars.colors.accent2};
    margin-top: ${getSpacing('sm')};
    display: block;

    ${getBreakpoint.md`
        display: none;
    `}
`

export const StepLabel = styled.span`
    margin-top: ${getSpacing('md')};
    display: none;

    ${getBreakpoint.md`
        display: inherit;
        font-size: ${vars.font.size.base};
    `}
`
