import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconPersonalDataSecurityProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconPersonalDataSecurity = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconPersonalDataSecurityProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>
                    {'.PersonalDataSecurity_svg__cls-1{fill:#002664}'}
                </style>
            </defs>
            <path
                className='PersonalDataSecurity_svg__cls-1'
                d='M18.05 42a1 1 0 01-1-1V30a1 1 0 012 0v11a1 1 0 01-1 1zm19-4a.94.94 0 01-.55-.17l-3-2a1 1 0 111.1-1.66l2.26 1.5 4.42-5.31a1 1 0 011.54 1.28l-5 6a1 1 0 01-.77.36z'
            />
            <path
                className='PersonalDataSecurity_svg__cls-1'
                d='M38.05 44a10 10 0 1110-10 10 10 0 01-10 10zm0-18a8 8 0 108 8 8 8 0 00-8-8z'
            />
            <path
                className='PersonalDataSecurity_svg__cls-1'
                d='M32.05 19h-28a3.87 3.87 0 00-4 4v16c0 5.13 4.3 9 10 9h17a9.22 9.22 0 006-2 13 13 0 01-1.93-1 7.64 7.64 0 01-4.07 1h-17c-4 0-8-2.4-8-7V23a1.87 1.87 0 012-2h28a1.93 1.93 0 011.87 1.41 12.46 12.46 0 011.92-.56A3.92 3.92 0 0032.05 19zm-7-2h-2v-6a5 5 0 00-10 0v6h-2v-6a7 7 0 0114 0z'
            />
            <path
                className='PersonalDataSecurity_svg__cls-1'
                d='M29 17h-2v-6a9 9 0 00-18 0v6H7v-6a11 11 0 1122 0z'
            />
            <path
                d='M21.21 31.83a5 5 0 11.62-7 5 5 0 01-.62 7z'
                fill='#d7153a'
            />
        </svg>
    )
}
