import { useMemo, useRef } from 'react'
import { isFunction } from '../misc/is'

type noop = (this: any, ...args: any[]) => any

type PickFunction<T extends noop> = (
    this: ThisParameterType<T>,
    ...args: Parameters<T>
) => ReturnType<T>

/** Returns a stable function that calls the updated/latest function passed as an argument  */
export function useMemoizedFn<T extends noop>(fn: T) {
    // @ts-ignore
    if (process.env.NODE_ENV !== 'production') {
        if (!isFunction(fn)) {
            console.error(
                `useMemoizedFn expected parameter is a function, got ${typeof fn}`,
            )
        }
    }

    const fnRef = useRef<T>(fn)

    /*
    why not write `fnRef.current = fn`?
    https://github.com/alibaba/hooks/issues/728

    Basically in order to enable Suspense React needs to be able to "rewind" this memoized function
    to the previous version, so that it can re-render the component with the previous version of the function
    using memo lets React remember what the previous function was and use it to re-render the component
    */
    fnRef.current = useMemo(() => fn, [fn])

    const memoizedFn = useRef<PickFunction<T>>()
    if (!memoizedFn.current) {
        memoizedFn.current = function (this, ...args) {
            return fnRef.current.apply(this, args)
        }
    }

    return memoizedFn.current as T
}
