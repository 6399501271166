import styled, { css } from 'styled-components'
import { getSpacing, marginMixin, pxToRem } from '@snsw-gel/utils'
import { FieldErrorWrapper, FieldLabel, MarginProps } from '@snsw-gel/field'
import { clsFlags, vars, createVar } from '@snsw-gel/theming'

export const CheckboxWrapper = styled.div<MarginProps>`
    position: relative;
    ${marginMixin as any}

    + ${FieldErrorWrapper} {
        margin-top: ${getSpacing(['md'])};
    }
`

export const STROKE_LEN = '27'
export const strokeVar = createVar('stroke-len', '0')
export const counterVar = createVar('change-counter', '0')

// This is updated at runtime just in case
const DEFAULT_STROKE_LEN = 26.22953224182129

export const CheckboxIndicatorWrapper = styled.span`
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border: 2px solid ${vars.colors.forms.border};
    background-color: ${vars.colors.bg};
    display: inline-flex;
    vertical-align: middle;
    margin-right: ${pxToRem(16)};
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};

    justify-content: center;
    align-items: center;

    pointer-events: none;

    svg {
        position: absolute;
        top: 4;
        left: 4;
        color: ${vars.colors.forms.border};
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        svg {
            color: currentColor;
        }
    }

    ${strokeVar.set(STROKE_LEN)}

    /* eslint-disable-next-line */
    path {
        ${strokeVar.set(DEFAULT_STROKE_LEN)}
        vector-effect: non-scaling-stroke;
        stroke-width: 2.4;
        stroke-dasharray: ${strokeVar};
        transition: stroke-dashoffset ${vars.transitions.hover},
            opacity ${vars.transitions.idle};
        opacity: 0;
        stroke-dashoffset: calc((${counterVar} + 1) * ${strokeVar});
    }
`

export const ClarifyWrapper = styled.div`
    margin-left: 3rem;
`

export const EditorWrapper = styled.div`
    > span {
        font-size: ${vars.font.size.base};
        color: ${vars.colors.forms.text};
    }
    margin-top: ${pxToRem(8)};
    margin-bottom: -${pxToRem(8)};
    margin-left: ${pxToRem(11)};
    border-left: 8px solid #d8d8d8;
    padding: ${getSpacing([6, 'sm', 6, 'sm'])};
`

export const CheckboxLabel = styled(FieldLabel)`
    display: inline-block;
    font-weight: ${vars.font.weight.book};
    line-height: ${vars.font.lineHeight};
    padding: ${pxToRem(4)} 0 ${pxToRem(4)} ${pxToRem(48)};

    &.${clsFlags.error} {
        ${CheckboxIndicatorWrapper} {
            border-color: ${vars.colors.forms.error};
            box-shadow: none;
        }
    }
`

export const HiddenRadioCheckbox = css`
    position: absolute;
    opacity: 0;
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    &:not(:disabled) {
        cursor: pointer;
    }
`

export const CheckboxInput = styled.input`
    ${HiddenRadioCheckbox}

    &:checked + ${CheckboxLabel} ${CheckboxIndicatorWrapper} {
        background-color: ${vars.colors.bg};

        path {
            opacity: 1;
            clip-path: calc(${counterVar} * ${strokeVar});
        }
    }

    &:focus ~ ${CheckboxLabel} ${CheckboxIndicatorWrapper} {
        outline: 2px solid ${vars.colors.focus};
        outline-offset: 2px;
    }

    &:disabled + ${CheckboxLabel} {
        ${CheckboxIndicatorWrapper} {
            background-color: ${vars.colors.bgAccent};
        }
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        &:focus ~ ${CheckboxLabel} ${CheckboxIndicatorWrapper} {
            -ms-high-contrast-adjust: none;
            forced-color-adjust: none;
            outline-color: Highlight;
            background-color: Background;
            border-color: InactiveBorder;
            color: InfoText;
        }
    }
`
