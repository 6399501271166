import React from 'react'
import PropTypes from 'prop-types'
import { Container, PageWrapper } from './ContentContainer.styled'

const ContentContainer = ({ className, children }) => (
    <PageWrapper className={className}>
        <Container>{children}</Container>
    </PageWrapper>
)

ContentContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

export default ContentContainer
