import styled from 'styled-components'
import { tokens } from '@snsw-gel/tokens'
import { pxToRem } from '@snsw-gel/utils'

const { colors } = tokens

export const SuccessAnimationContainer = styled.div`
    text-indent: 100%;
    overflow: hidden;
    width: 100%;

    svg {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        left: 50%;
        width: ${pxToRem(70)};
        height: ${pxToRem(70)};
    }

    .circle {
        fill: ${colors.borders.success};
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }

    .tick {
        fill: none;
        stroke: white;
        stroke-width: 8;
        stroke-linejoin: round;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 50;
        stroke-dashoffset: 50;
        transition: stroke-dashoffset 1s 0.5s ease-out;
    }

    .drawn + svg .path {
        opacity: 1;
        stroke-dashoffset: 0;
    }
`
