import { getSpacing, pxToRem, marginMixin } from '@snsw-gel/utils'
import styled, { css } from 'styled-components'
import { vars, clsFlags, createVar } from '@snsw-gel/theming'

export const errorStyles = css`
    border-color: ${vars.colors.forms.error};
    /* color: ${vars.colors.forms.text}; */
    box-shadow: none;
`

export const inputWidthVar = createVar('inputWidth', '100%')

export const baseInputStyles = css`
    width: 100%;
    max-width: ${inputWidthVar};
    font-size: ${vars.font.size.base};
    color: ${vars.colors.bg.text};
    background-color: ${vars.colors.bg};
    border: 2px solid ${vars.colors.forms.border};
    border-radius: 6px;
    padding: ${getSpacing(['none', 13])};
    margin-top: ${pxToRem(4)};
    transition: border-color 0.25s ease, background-color 0.25s ease;
    ${props => !props.rows && `height: ${pxToRem(48)}`};
    cursor: text;
    appearance: none;

    &.--default-rows {
        height: ${pxToRem(48)};
    }

    &::placeholder {
        /* Internet Explorer 10-11 */
        color: ${vars.colors.forms.helpText};
        opacity: 1;
    }
    &.focus-visible,
    &:focus {
        outline: 2px solid ${vars.colors.focus};
        outline-offset: 2px;
    }
    &:disabled {
        opacity: 1;
        background-color: ${vars.colors.bgAccent};
    }
    &::-ms-clear {
        display: none;
    }
    &:-moz-ui-invalid {
        box-shadow: none;
    }
    &.${clsFlags.error}, .${clsFlags.error} & {
        ${errorStyles}
    }
`

export const affixStyles = css`
    ${props =>
        (props.prefix || props.suffix) &&
        css`
            position: relative;
            margin-bottom: 0;
            display: block;
            background-clip: padding-box;
        `};

    ${props =>
        props.prefix &&
        css`
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `};

    ${props =>
        props.suffix &&
        css`
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `};
`

export const FieldErrorWrapper = styled.span`
    display: flex;
    align-items: flex-start;
    color: ${vars.colors.forms.error};
    margin-top: ${getSpacing(['xs'])};

    > svg {
        > g > :last-child {
            fill: ${vars.colors.forms.error};
        }
        flex-shrink: 0;
        margin-top: ${pxToRem(4)};
    }
    > span {
        margin-left: ${pxToRem(12)};
    }
`

export const FieldLabel = styled.label`
    line-height: ${vars.font.lineHeight.default};
    font-weight: ${vars.font.weight.medium};
    display: block;
    color: ${vars.colors.bg.text};
    margin-bottom: 0;
`

export const FieldHelp = styled.span`
    font-size: ${pxToRem(14)};
    line-height: ${vars.font.lineHeight.heading};
    font-weight: ${vars.font.weight.book};
    color: ${vars.colors.forms.helpText};
    margin: 0;
    display: block;
`

// Exact same as FieldHelp but used when describing multiple components
export const FieldGroupHelp = styled(FieldHelp)``

export const FieldWrapper = styled.div`
    ${marginMixin}
`

export const AffixWrapperStyled = styled.div`
    position: relative;
    display: flex;
`

export const PrefixWrapperStyled = styled.div`
    display: flex;
`

export const SuffixWrapperStyled = styled.div`
    display: flex;
`

export const AffixStyled = css`
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: ${vars.font.size.base};
    font-weight: ${vars.font.weight.medium};
    line-height: ${vars.font.lineHeight.default};
    color: ${vars.colors.forms.text};
    background: ${vars.colors.bg.border};
    text-align: center;
    white-space: nowrap;
    border: 2px solid ${vars.colors.forms.border};

    &.${clsFlags.error} {
        ${errorStyles}
    }
`

export const PrefixStyled = styled.span`
    ${AffixStyled}
    border-radius: 6px 0px 0px 6px;
    border-right: none;
    padding: ${getSpacing([6, 12, 6, 10])};
    margin-top: ${pxToRem(4)};
`

export const SuffixStyled = styled.span`
    ${AffixStyled}
    border-radius: 0px 6px 6px 0px;
    border-left: none;
    padding: ${getSpacing([6, 10, 6, 12])};
    margin-top: ${pxToRem(4)};
`
