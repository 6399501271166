/**
 * Generic state for when a an input is used to control a picker.
 */

import { useControllableValue } from '@snsw-gel/utils'
import { useCallback, useEffect, useRef, useState } from 'react'

export type MenuTriggerAction = 'focus' | 'input' | 'manual'
export type FocusStrategy =
    | 'first'
    | 'last'
    | ((root: HTMLElement) => HTMLElement)

export interface OverlayTriggerProps {
    /** Whether the overlay is open by default (controlled). */
    isOpen?: boolean
    /** Whether the overlay is open by default (uncontrolled). */
    defaultOpen?: boolean
    /** Handler that is called when the overlay's open state changes. */
    onOpenChange?: (isOpen: boolean) => void
}

export interface OverlayTriggerState {
    /** Whether the overlay is currently open. */
    readonly isOpen: boolean
    /** Sets whether the overlay is open. */
    setOpen(isOpen: boolean): void
    /** Opens the overlay. */
    open(): void
    /** Closes the overlay. */
    close(): void
    /** Toggles the overlay's visibility. */
    toggle(): void
}

export function useOverlayTriggerState(
    props: OverlayTriggerProps,
): OverlayTriggerState {
    const [isOpen, setOpen] = useControllableValue(
        props.isOpen,
        props.onOpenChange,
        props.defaultOpen,
        false,
    )

    const open = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    const close = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const toggle = useCallback(() => {
        setOpen(!isOpen)
    }, [setOpen, isOpen])

    return {
        isOpen,
        setOpen,
        open,
        close,
        toggle,
    }
}

interface SelectedProps<SelectedValue extends string | {}> {
    selected?: SelectedValue | null
    onSelected?: (selected: SelectedValue | null) => void
    defaultSelected?: SelectedValue
}

interface PickerProps<SelectedValue extends string | {}>
    extends SelectedProps<SelectedValue>,
        OverlayTriggerProps {
    inputValue?: string
    onInputChange?: (inputValue: string) => void
    defaultInputValue?: string
    shouldCommitOnBlur?: boolean
    allowsCustomValue?: boolean

    menuTrigger?: MenuTriggerAction

    onOpenChange?: (isOpen: boolean, trigger?: MenuTriggerAction) => void

    getSelectedFromInputValue?: (inputValue: string) => SelectedValue | null
    getInputValueFromSelected?: (selected: SelectedValue) => string
}

export function useComboPickerState<SelectedValue extends string | {}>(
    props: PickerProps<SelectedValue>,
) {
    const [isInputFocused, setInputFocusedState] = useState(false)

    let defaultInputValue = props.defaultInputValue

    if (defaultInputValue === undefined && props.getInputValueFromSelected) {
        if (props.selected !== undefined) {
            if (props.selected) {
                defaultInputValue = props.getInputValueFromSelected(
                    props.selected,
                )
            } else {
                defaultInputValue = ''
            }
        } else if (props.defaultSelected !== undefined) {
            if (props.defaultSelected) {
                defaultInputValue = props.getInputValueFromSelected(
                    props.defaultSelected,
                )
            } else {
                defaultInputValue = ''
            }
        }
    }

    const [inputValue, setInputValue] = useControllableValue(
        props.inputValue,
        props.onInputChange,
        props.defaultInputValue || defaultInputValue,
        '',
    )

    const onSelectionChange = (value: SelectedValue | null) => {
        props.onSelected?.(value ?? null)

        // If key is the same, reset the inputValue and close the menu
        // (scenario: user clicks on already selected option)
        if (isEqualSelection(value, selectedValue)) {
            resetInputValue()
        }
    }

    const focussedSelectionRef = useRef<null | SelectedValue>(null)

    const onOpenChange = (open: boolean) => {
        if (props.onOpenChange) {
            props.onOpenChange(open, open ? menuOpenTrigger.current : undefined)
        }
    }

    const [focusStrategy, setFocusStrategy] = useState<FocusStrategy | null>(
        null,
    )
    const triggerState = useOverlayTriggerState({
        isOpen: props.isOpen,
        defaultOpen: props.defaultOpen,
        onOpenChange,
    })
    const [showAllItems, setShowAllItems] = useState(triggerState.isOpen)
    const menuOpenTrigger = useRef<MenuTriggerAction | undefined>(
        triggerState.isOpen ? 'manual' : undefined,
    )

    const [selectedValue, setSelectedValue] = useControllableValue(
        props.selected,
        onSelectionChange,
        props.defaultSelected || props.getSelectedFromInputValue?.(inputValue),
        null,
    )

    const [lastValue, setLastValue] = useState(inputValue)
    const lastSelectedRef = useRef(selectedValue)

    function getSelectionAsString(value: SelectedValue) {
        return props.getInputValueFromSelected
            ? props.getInputValueFromSelected(value)
            : String(value)
    }

    const resetInputValue = () => {
        const itemText = selectedValue
            ? getSelectionAsString(selectedValue)
            : ''
        setLastValue(itemText)
        setInputValue(itemText)
    }

    const isEqualSelection = (
        a: SelectedValue | null,
        b: SelectedValue | null,
    ) => {
        if ((a === null) !== (b === null)) {
            return false
        }
        if (!a && a === b) {
            return true
        }

        return getSelectionAsString(a!) === getSelectionAsString(b!)
    }

    useEffect(() => {
        // Open and close menu automatically when the input value changes if the input is focused,
        // and there are items in the collection or allowEmptyCollection is true.
        if (
            isInputFocused &&
            !triggerState.isOpen &&
            inputValue !== lastValue &&
            props.menuTrigger !== 'manual'
        ) {
            open(null, 'input')
        }

        // Close the menu if the collection is empty. Don't close menu if filtered collection size is 0
        // but we are currently showing all items via button press
        if (!showAllItems && triggerState.isOpen) {
            closeMenu()
        }

        // Close when an item is selected.
        if (
            selectedValue != null &&
            selectedValue !== lastSelectedRef.current
        ) {
            closeMenu()
        }

        // Clear focused key when input value changes and display filtered collection again.
        if (inputValue !== lastValue) {
            // Set selectedKey to null when the user clears the input.
            // If controlled, this is the application developer's responsibility.
            focussedSelectionRef.current = null
            if (
                inputValue === '' &&
                (props.inputValue === undefined || props.selected === undefined)
            ) {
                setSelectedValue(
                    (inputValue
                        ? props.getSelectedFromInputValue?.(inputValue)
                        : null) ?? null,
                )
            }
        }

        // If the selectedKey changed, update the input value.
        // Do nothing if both inputValue and selectedKey are controlled.
        // In this case, it's the user's responsibility to update inputValue in onSelectionChange.
        if (
            !isEqualSelection(selectedValue, lastSelectedRef.current) &&
            (props.inputValue === undefined || props.selected === undefined)
        ) {
            resetInputValue()
        } else if (lastValue !== inputValue) {
            setLastValue(inputValue)
        }

        // Update the inputValue if the selected item's text changes from its last tracked value.
        // This is to handle cases where a selectedKey is specified but the items aren't available (async loading) or the selected item's text value updates.
        // Only reset if the user isn't currently within the field so we don't erroneously modify user input.
        // If inputValue is controlled, it is the user's responsibility to update the inputValue when items change.
        const selectedItemText = selectedValue
            ? getSelectionAsString(selectedValue)
            : ''
        if (
            !focussedSelectionRef.current &&
            selectedValue != null &&
            props.inputValue === undefined &&
            isEqualSelection(selectedValue, lastSelectedRef.current)
        ) {
            setLastValue(selectedItemText)
            setInputValue(selectedItemText)
        }

        lastSelectedRef.current = selectedValue
    })

    useEffect(() => {
        const selected = props.getSelectedFromInputValue?.(inputValue)
        if (
            props.getSelectedFromInputValue &&
            props.selected === undefined &&
            inputValue &&
            selected &&
            !isEqualSelection(selected, selectedValue)
        ) {
            setSelectedValue(selected)
        }
    }, [inputValue])

    const open = (
        focusStrategy: FocusStrategy | null = null,
        trigger?: MenuTriggerAction,
    ) => {
        const displayAllItems =
            trigger === 'manual' ||
            (trigger === 'focus' && props.menuTrigger === 'focus')

        if (displayAllItems) {
            if (displayAllItems && !triggerState.isOpen) {
                // Show all items if menu is manually opened. Only care about this if items are undefined
                setShowAllItems(true)
            }

            // Prevent open operations from triggering if there is nothing to display
            // Also prevent open operations from triggering if items are uncontrolled but defaultItems is empty, even if displayAllItems is true.
            // This is to prevent comboboxes with empty defaultItems from opening but allow controlled items comboboxes to open even if the inital list is empty (assumption is user will provide swap the empty list with a base list via onOpenChange returning `menuTrigger` manual)

            menuOpenTrigger.current = trigger
            setFocusStrategy(focusStrategy)
            triggerState.open()
        }
    }

    const toggleMenu = useCallback(
        (focusStrategy: FocusStrategy | null = null) => {
            setFocusStrategy(focusStrategy)
            triggerState.toggle()
        },
        [triggerState],
    )

    const closeMenu = useCallback(() => {
        if (triggerState.isOpen) {
            triggerState.close()
        }
    }, [triggerState])

    const toggle = (
        focusStrategy: FocusStrategy | null = null,
        trigger?: MenuTriggerAction,
    ) => {
        const displayAllItems =
            trigger === 'manual' ||
            (trigger === 'focus' && props.menuTrigger === 'focus')
        // If the menu is closed and there is nothing to display, early return so toggle isn't called to prevent extraneous onOpenChange
        if (!displayAllItems && !triggerState.isOpen) {
            return
        }

        if (displayAllItems && !triggerState.isOpen) {
            // Show all items if menu is toggled open. Only care about this if items are undefined
            setShowAllItems(true)
        }

        // Only update the menuOpenTrigger if menu is currently closed
        if (!triggerState.isOpen) {
            menuOpenTrigger.current = trigger
        }

        toggleMenu(focusStrategy)
    }

    const commitSelection = () => {
        // If multiple things are controlled, call onSelectionChange
        if (props.selected !== undefined && props.inputValue !== undefined) {
            props.onSelected?.(selectedValue)

            // Stop menu from reopening from useEffect
            const itemText = selectedValue
                ? getSelectionAsString(selectedValue)
                : ''
            setLastValue(itemText)
            closeMenu()
        } else {
            // If only a single aspect of combobox is controlled, reset input value and close menu for the user
            resetInputValue()
            closeMenu()
        }
    }

    const commitCustomValue = () => {
        lastSelectedRef.current = null
        setSelectedValue(null)
        closeMenu()
    }

    const commitValue = () => {
        if (props.allowsCustomValue) {
            const itemText = selectedValue
                ? getSelectionAsString(selectedValue)
                : ''
            inputValue === itemText ? commitSelection() : commitCustomValue()
        } else {
            // Reset inputValue and close menu
            commitSelection()
        }
    }

    function commit() {
        if (focussedSelectionRef.current !== null) {
            // Reset inputValue and close menu here if the selected key is already the focused key. Otherwise
            // fire onSelectionChange to allow the application to control the closing.
            if (isEqualSelection(selectedValue, focussedSelectionRef.current)) {
                commitSelection()
            } else {
                setSelectedValue(focussedSelectionRef.current)
            }
        } else {
            commitValue()
        }
    }

    const setFocused = (isFocused: boolean) => {
        if (!isFocused && props.shouldCommitOnBlur) {
            commit()
        }
        setInputFocusedState(isFocused)
    }

    const revert = () => {
        if (props.allowsCustomValue && selectedValue == null) {
            commitCustomValue()
        } else {
            commitSelection()
        }
    }

    return {
        ...triggerState,
        open,
        close: commitValue,
        toggle,
        revert,
        inputValue,
        setInputValue: (value: string) => {
            setInputValue(value)
        },
        selectedValue,
        setSelectedValue,
        setFocusedInput: (isFocused: boolean) => {
            setFocused(isFocused)
        },
        setFocusedSelection: (value: SelectedValue | null) => {
            focussedSelectionRef.current = value
        },
    }
}
