type ValueType =
    | { [k: string]: any }
    | (((...args: any) => any) & { [k: string]: any })

export function get(obj: ValueType, path: string[]): any {
    let target = obj
    for (let i = 0; i < path.length; i++) {
        const prop = path[i]
        const targetType = typeof target
        if (targetType !== 'object' && targetType !== 'function') {
            return undefined
        }
        target = target[prop]
    }
    return target
}

export function del(obj: ValueType, path: string[], clean = false) {
    let target = obj

    for (let i = 0; i < path.length - 1; i++) {
        target = target[path[i]]
    }
    delete target[path[path.length - 1]]

    if (clean && !Object.keys(target).length) {
        del(obj, path.slice(0, -1), true)
    }
}

export function set(obj: ValueType, path: string[], value: any, clean = false) {
    let target = obj
    for (let i = 0; i < path.length - 1; i++) {
        const prop = path[i]
        const targetType = typeof target[prop]
        if (targetType !== 'object' && targetType !== 'function') {
            target[prop] = {}
        }
        target = target[prop]
    }
    if (value === undefined) {
        del(obj, path, clean)
    } else {
        target[path[path.length - 1]] = value
    }
}

export function getFromPath(path: string[]) {
    return (obj: ValueType) => get(obj, path)
}
