import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconNotificationInfoProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconNotificationInfo = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconNotificationInfoProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M9 11V8a1 1 0 10-2 0v3a1 1 0 002 0zm-.81-5a.6.6 0 00.19-.06.56.56 0 00.17-.09 1 1 0 00.16-.12 1 1 0 000-1.42A1 1 0 007 5a1 1 0 001 1h.19z'
                    fill='#FFF'
                />
                <path
                    d='M8 0a8 8 0 110 16A8 8 0 018 0zm0 7a1 1 0 00-1 1v3a1 1 0 002 0V8a1 1 0 00-1-1zm.19-1a.6.6 0 00.19-.06.56.56 0 00.17-.09 1 1 0 00.16-.12 1 1 0 000-1.42A1 1 0 007 5a1 1 0 001 1h.19z'
                    fill='#2E5299'
                />
            </g>
        </svg>
    )
}
