// @ts-ignore
import { tokens } from '@snsw-gel/tokens'
const { spacing, colors } = tokens

export function getSize(size: string | number) {
    if (typeof size === 'string') {
        return spacing.icons[size] || size
    } else if (typeof size === 'number') {
        return size
    }
}

export function getSystemColor(color: string) {
    return colors.icons[color] || color
}
