import styled from 'styled-components'
import { pxToRem, getTypeSize } from '@snsw-gel/utils'
import { vars, createVar } from '@snsw-gel/theming'

export const heightToAnimate = createVar('accordion-expand-height')
const expandTransitionDuration = createVar('accordion-expand-duration')

export const StyledAccordion = styled.div`
    width: 100%;
`

export const StyledAccordionHeader = styled.div`
    margin-top: 8px;
`

export const StyledAccordionButton = styled.button`
    background-color: ${vars.colors.bgAccent};
    color: ${vars.colors.bgAccent.text};
    margin: 0;
    display: flex;
    padding: ${pxToRem(16)} ${pxToRem(24)};
    font-weight: bold;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    ${getTypeSize('sm')};
    border: none;
    cursor: pointer;

    transition-property: background-color, color;
    transition-duration: ${vars.transitions.idle};
    transition-timing-function: ${vars.easings.natural};
    transition-delay: 0s;

    &:hover {
        transition-delay: ${vars.transitions.delay};

        svg {
            transition-delay: ${vars.transitions.delay};
        }
    }

    &:hover,
    &[aria-expanded='true'] {
        transition-duration: ${vars.transitions.hover};
        background-color: ${vars.colors.accent3.default};
        color: ${vars.colors.accent3.text};

        svg {
            fill: currentColor;
        }
    }

    &:focus {
        outline: 2px solid ${vars.colors.accent3.default};
        outline-offset: 2px;
    }

    &:active {
        transition-duration: ${vars.transitions.active};
        transition-delay: 0s;
    }

    span {
        width: 100%;
        margin: 0 ${pxToRem(16)} 0 0;
        text-align: left;
    }

    svg {
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        flex: 0 0 ${pxToRem(16)};
        align-self: center;
        fill: ${vars.colors.accent2};
        transition-property: fill;
        transition-duration: ${vars.transitions.idle};
        transition-timing-function: ${vars.easings.natural};
        transition-delay: 0s;
    }
`

export const StyledAccordionContentContainer = styled.div`
    padding: 24px 20px;
    border-left: solid 4px ${vars.colors.accent3};
`

export const StyledAccordionContent = styled.section`
    overflow: hidden;

    height: auto;

    ${heightToAnimate.set('20px')}
    ${expandTransitionDuration.set(vars.transitions.hover)}

    &.is-hidden {
        height: 0;
        visibility: hidden;
    }

    ${StyledAccordionContentContainer} {
        opacity: 1;
        transform: translateY(0px);
    }

    &.is-hidden ${StyledAccordionContentContainer} {
        transform: translateY(calc(-0.75 * ${heightToAnimate}));
        opacity: 0;
    }

    &.is-animating {
        visibility: visible;
        transition: height ${expandTransitionDuration} ${vars.easings.natural},
            visibility 0s;

        ${StyledAccordionContentContainer} {
            transition-property: transform, opacity;
            transition-duration: ${expandTransitionDuration};
            transition-timing-function: ${vars.easings.natural};
        }

        &.is-hidden ${StyledAccordionContentContainer} {
            transform: translateY(calc(-0.75 * ${heightToAnimate}));
            opacity: 0;
        }

        &.is-hidden {
            transition: height ${expandTransitionDuration}
                    ${vars.easings.natural},
                visibility 0s linear ${expandTransitionDuration};

            ${StyledAccordionContentContainer} {
                pointer-events: none;
            }
        }
    }
`

export const StyledAccordionToggles = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    align-content: center;

    button:first-child {
        margin-right: ${pxToRem(16)};
    }
`
