import React, { useEffect } from 'react'

export function useListener<
    T extends { addEventListener: any; removeEventListener: any },
    EventType extends Event = Event,
>(
    element: React.RefObject<T> | T | null,
    eventName: string,
    handler: (event: EventType) => void,
) {
    useEffect(() => {
        if (!element) {
            return
        }
        const currentElement = 'current' in element ? element.current : element
        if (!currentElement) {
            return
        }
        currentElement.addEventListener(eventName, handler)
        return () => currentElement.removeEventListener(eventName, handler)
    }, [eventName, handler, element])
}
