export function combineAriaDescribedBy(
    ...args: (string | false | undefined | null | 0)[]
) {
    const set = new Set<string>()
    for (const arg of args) {
        if (typeof arg === 'string' && arg) {
            for (const id of arg.split(' ')) {
                set.add(id)
            }
        }
    }
    return set.size ? [...set].join(' ') : undefined
}
