import React from 'react'
import PropTypes from 'prop-types'
import { SROnly } from '@snsw-gel/accessibility'
import AnimatedIconSuccess from './AnimatedIconSuccess'
import {
    Dimmer,
    LoaderContainer,
    LoaderMessage,
    SpinnerImageContainer,
} from '@snsw-gel/loader'

export const SuccessIndicator = props => {
    const { className, active, label, ...rest } = props

    return (
        <Dimmer className={className} active={active} fullPage {...rest}>
            <SROnly role='status'>{label}</SROnly>
            <LoaderContainer data-testid='hide-items' aria-hidden={true}>
                <SpinnerImageContainer>
                    <AnimatedIconSuccess />
                </SpinnerImageContainer>
                <LoaderMessage fullPage>
                    {label}
                    <br />
                </LoaderMessage>
            </LoaderContainer>
        </Dimmer>
    )
}

SuccessIndicator.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.string.isRequired,
}

SuccessIndicator.defaultProps = {
    className: '',
    active: false,
}
