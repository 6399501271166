import React, {
    FC,
    ReactNode,
    cloneElement,
    createContext,
    isValidElement,
    useContext,
    useMemo,
} from 'react'
import { useMemoCompare, shallowEqualSymbols } from '@snsw-gel/utils'

export interface SlotsContextValue {
    [key: symbol | string]: ReactNode
}

const SlotsContext = createContext<SlotsContextValue>({})

export interface ProvideSlotsProps<Slots extends SlotsContextValue> {
    children: ReactNode
    slots?: Partial<Slots>
}

/**
 * Render this above a component that uses slots to have the slots filled
 *
 * @example
 * import { buttonSlots } from '@snsw-gel/button'
 *
 * <Slots slots={buttonSlots({ prefix: <div></div> })}>
 *
 */
export function Slots<FilledSlots extends SlotsContextValue>(
    props: ProvideSlotsProps<FilledSlots>,
) {
    const slotsContext = useContext(SlotsContext)

    const newSlots = useMemoCompare(
        () => props.slots || {},
        shallowEqualSymbols,
    )

    const slots = useMemo(() => {
        if (slotsContext && newSlots) {
            return {
                ...slotsContext,
                ...newSlots,
            }
        }

        return slotsContext || newSlots
    }, [slotsContext, newSlots])

    return (
        <SlotsContext.Provider value={slots}>
            {props.children}
        </SlotsContext.Provider>
    )
}

export function useSlots<SlotTypes extends SlotsContextValue>() {
    return useContext(SlotsContext) as SlotTypes
}

export interface SlotProps<Slots extends SlotsContextValue> {
    /** the key of the slot */
    name: keyof Slots
    /** fallback content */
    children?: ReactNode
    /** wraps the element when it exists */
    wrap?: FC<{ children: ReactNode }>
    /** passes props to the underlying element */
    props?: { [k: string]: any }
}

/**
 * Render this inside a component that uses slots to fill the slot
 * @example
 * <Slot name={buttonSlots.slots.prefix}>
 *  <div>Prefix fallback</div>
 * </Slot>
 * */
export function Slot<FilledSlots extends SlotsContextValue>(
    props: SlotProps<FilledSlots>,
) {
    const { name, children = null, wrap: Wrap } = props
    const slots = useSlots() as FilledSlots

    const slotValue = slots ? slots[name] : undefined
    const value = slotValue !== undefined ? slotValue : children

    const content =
        props.props && isValidElement(value)
            ? cloneElement(value, props.props)
            : value

    if (value !== null && value !== undefined && Wrap) {
        return <Wrap>{content}</Wrap>
    }

    return content as JSX.Element
}
