import React from 'react'
import PropTypes from 'prop-types'
import {
    Radio,
    RadioItemEditorContainer,
    RadioGroupFieldset,
    RadioItemClarifyContainer,
    RadioInput,
    RadioItem as StyledRadioItem,
    RadioLabel,
} from './FormRadioGroup.styled'
import { useId } from '@snsw-gel/utils'
import classNames from 'classnames'

export const FormRadioGroup = ({
    id,
    name,
    className,
    legend,
    helpMessage,
    fieldType,
    options,
    value,
    hasError,
    errorMessage,
    vertical,
    onChange,
    inputRef,
    margin,
    ...rest
}) => {
    const fieldClass = classNames(className, !vertical && 'radio-group__inline')

    const elemID = useId(id)

    return (
        <RadioGroupFieldset
            {...rest}
            id={`${elemID}`}
            inline={!vertical}
            aria-invalid={hasError}
            className={fieldClass}
            margin={margin}
            legend={legend}
            helpMessage={helpMessage}
            hasError={hasError}
            errorMessage={errorMessage}
            smallLegend={true}
        >
            <Radio>
                {options.map((option, index) => (
                    <RadioItem
                        key={`${elemID}-${index}`}
                        id={`${elemID}-${index}`}
                        option={option}
                        value={value}
                        fieldType={fieldType}
                        onSelection={onChange}
                        hasError={hasError}
                        name={name}
                        inputRef={inputRef}
                        vertical={vertical}
                    />
                ))}
            </Radio>
        </RadioGroupFieldset>
    )
}

FormRadioGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    legend: PropTypes.string.isRequired,
    helpMessage: PropTypes.string,
    fieldType: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.node.isRequired,
            clarify: PropTypes.string,
        }),
    ).isRequired,
    value: PropTypes.any,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    vertical: PropTypes.bool,
    onChange: PropTypes.func,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf([
                'none',
                'xs',
                'sm',
                'md',
                'lg',
                'xl',
                'xxl',
                'xxxl',
            ]),
        ]),
    }),
}

export const RadioItem = ({
    id,
    name,
    option,
    value,
    fieldType,
    onSelection,
    hasError,
    inputRef,
    vertical,
}) => {
    const handleClick = e => {
        if (e.target.tagName === 'INPUT') {
            e.target.checked = true

            let selectedValue = e.target.value
            if (fieldType === 'boolean') {
                selectedValue = stringToBoolean(selectedValue)
            }
            onSelection?.(selectedValue)
        }
    }

    const stringToBoolean = b => b.toLowerCase() === 'true'

    const checked = value === undefined ? value : option.value === value
    const editor = checked && option.editor ? option.editor : undefined
    const clarify = option.clarify && vertical ? option.clarify : undefined

    return (
        <>
            <StyledRadioItem onClick={handleClick}>
                <RadioInput
                    className={hasError ? 'error' : ''}
                    name={name}
                    type='radio'
                    id={id}
                    value={option.value}
                    ref={inputRef}
                    aria-describedby={clarify && `${id}-clarifyText`}
                    checked={checked}
                    onChange={Function.prototype}
                />
                <RadioLabel htmlFor={id} hasError={hasError}>
                    {option.label}
                </RadioLabel>
            </StyledRadioItem>
            {editor && (
                <RadioItemEditorContainer>{editor}</RadioItemEditorContainer>
            )}
            {clarify && (
                <RadioItemClarifyContainer id={`${id}-clarifyText`}>
                    {clarify}
                </RadioItemClarifyContainer>
            )}
        </>
    )
}

RadioItem.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    hasError: PropTypes.bool,
    option: PropTypes.shape({
        editor: PropTypes.node,
        clarify: PropTypes.string,
    }),
    value: PropTypes.any,
    fieldType: PropTypes.string,
    onSelection: PropTypes.func,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
