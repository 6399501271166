import styled, { css } from 'styled-components'
import { pxToRem } from '@snsw-gel/utils'
import { vars } from '@snsw-gel/theming'

export const textLinkStyles = css`
    text-decoration: underline;

    &:focus {
        outline: 2px solid ${vars.colors.accent3.default};
        outline-offset: 2px;
    }

    svg {
        fill: ${vars.colors.accent3.default};
        width: ${pxToRem(12)};
        height: ${pxToRem(12)};
        margin-left: ${pxToRem(4)};

        @media (-ms-high-contrast: active), (forced-colors: active) {
            fill: ${vars.colors.bg.default};
        }
    }
`

export const LinkContainer = styled.a`
    ${textLinkStyles}
`
