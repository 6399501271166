import styled from 'styled-components'
import PropTypes from 'prop-types'
import { vars } from '@snsw-gel/theming'

const SlidingTransition = styled.div`
    position: fixed;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    z-index: 2000;

    transition: transform ${vars.transitions.idle} ${vars.easings.natural};

    transform: translateX(0);

    &.out {
        transform: translateX(110%);
    }

    @media (max-width: 768px) {
        left: 30%;
        width: 70%;
    }
`

SlidingTransition.propTypes = {
    alignment: PropTypes.string,
}

export default SlidingTransition
