import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    MouseEvent,
    ReactNode,
} from 'react'
import { Button } from '@snsw-gel/button'
import { Portal, useId } from '@snsw-gel/utils'
import FocusLock from 'react-focus-lock'
import {
    StyledModal,
    Overlay,
    BgClicker,
    StyledModalButtonGroup,
    StyledModalFooter,
    StyledModalTitle,
    StyledModalOverflow,
    StyledModalContainer,
    StyledModalBody,
    StyledModalHeader,
    StyledModalCloseButton,
} from './Modal.styled'
import { SROnly } from '@snsw-gel/accessibility'
import { IconClose } from '@snsw-gel/icons'

export interface ModalProps {
    title: string
    /** Accepts up to two items in `Array` each item is an object
     *
     * The following structure is an example of creating one button */
    buttons: Array<{
        text: string
        onClick: (e: MouseEvent<HTMLButtonElement>) => any
    }>
    children: ReactNode
    description?: string
    /** Accepts a function which is used to close the modal */
    onClose?: () => any
    id?: string
    className?: string
}

export function Modal(props: ModalProps) {
    const { title, buttons, children, description, onClose, id, className } =
        props

    const elemID = useId(id)
    const modalStart = `${elemID}-start`
    const modalTitle = `${elemID}-title`
    const modalDesc = `${elemID}-desc`
    const buttonGroup = buttons
        .slice(0, 2)
        .reverse()
        .map(({ text, onClick }, index) => {
            const hasMultipleButtons = buttons.length > 1
            const isSecondary = hasMultipleButtons ? index === 0 : false
            return (
                <ModalButtons
                    key={`${text}_modal`}
                    text={text}
                    onClick={onClick}
                    isSecondary={isSecondary}
                />
            )
        })

    const scrollDivRef = useRef<HTMLDivElement>(null)
    const titleRef = useRef<HTMLHeadingElement>(null)
    const modalRef = useRef<HTMLDivElement>(null)
    const returnFocusRef = useRef<HTMLElement>()

    const [isContentFocusable, setContentFocusable] = useState(false)

    const setContentFocus = () => {
        const target = scrollDivRef.current
        target && setContentFocusable(target.scrollHeight > target.clientHeight)
    }

    useEffect(() => {
        const isFocusWithinModal =
            Boolean(document.activeElement) &&
            modalRef.current?.contains(document.activeElement)

        if (!isFocusWithinModal) {
            returnFocusRef.current = document.activeElement as HTMLElement
            titleRef.current?.focus()
            setContentFocus()
        }

        return () => {
            returnFocusRef.current?.focus()
        }
    }, [])

    const escFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.keyCode === 27 && onClose) {
                onClose()
            }
        },
        [onClose],
    )

    useEffect(() => {
        if (onClose) {
            document.addEventListener('keydown', escFunction)

            return () => {
                document.removeEventListener('keydown', escFunction)
            }
        }
    }, [escFunction, onClose])

    return (
        <Portal id='modal-portal'>
            <FocusLock returnFocus>
                <Overlay>
                    {onClose && (
                        <BgClicker
                            data-testid='modal-dimmer'
                            onClick={onClose}
                        />
                    )}
                    <StyledModal
                        ref={modalRef}
                        id={elemID}
                        className={className}
                        role='dialog'
                        aria-modal='true'
                        aria-labelledby={`${modalStart} ${modalTitle}`}
                        {...(description && { 'aria-describedby': modalDesc })}
                    >
                        <StyledModalContainer>
                            <StyledModalBody data-testid='modal-body'>
                                <StyledModalOverflow
                                    ref={scrollDivRef}
                                    {...(isContentFocusable && {
                                        tabIndex: 0,
                                    })}
                                >
                                    <StyledModalHeader>
                                        <SROnly id={modalStart} tabIndex={-1}>
                                            Start of dialog
                                        </SROnly>
                                        <StyledModalTitle
                                            ref={titleRef}
                                            id={modalTitle}
                                            tabIndex={-1}
                                        >
                                            {title}
                                        </StyledModalTitle>
                                    </StyledModalHeader>
                                    {description && (
                                        <p
                                            className='description1'
                                            id={modalDesc}
                                        >
                                            {description}
                                        </p>
                                    )}
                                    {children}
                                    <StyledModalFooter data-testid='modal-footer'>
                                        <StyledModalButtonGroup>
                                            {buttonGroup}
                                        </StyledModalButtonGroup>
                                    </StyledModalFooter>
                                </StyledModalOverflow>
                            </StyledModalBody>
                            {onClose && (
                                <StyledModalCloseButton
                                    type='button'
                                    onClick={onClose}
                                >
                                    <IconClose />
                                    <SROnly>Close Modal</SROnly>
                                </StyledModalCloseButton>
                            )}
                        </StyledModalContainer>
                    </StyledModal>
                </Overlay>
            </FocusLock>
        </Portal>
    )
}

interface ModalButtonProps {
    text: string
    onClick: (event: MouseEvent<HTMLButtonElement>) => any
    isSecondary: boolean
}
const ModalButtons = (props: ModalButtonProps) => (
    <Button
        onClick={props.onClick}
        variant={props.isSecondary ? 'secondary' : 'primary'}
    >
        {props.text}
    </Button>
)
