import React from 'react'
import PropTypes from 'prop-types'
import {
    LoaderContainer,
    LoaderMessage,
    SpinnerImageContainer,
} from './Loader.styled'
import { Spinner } from './Spinner.styled'

export const Loader = ({ content = 'Loading...', fullPage = false }) => (
    <LoaderContainer aria-hidden={true}>
        <SpinnerImageContainer>
            <Spinner color='#929ea4' />
        </SpinnerImageContainer>
        <LoaderMessage fullPage={fullPage}>
            {content}
            <br />
        </LoaderMessage>
    </LoaderContainer>
)

Loader.propTypes = {
    content: PropTypes.string,
    fullPage: PropTypes.bool,
}
