import { Ref } from 'react'

export function bindRefs<T>(...refs: (Ref<T> | undefined)[]) {
    return (value: T) => {
        for (const ref of refs) {
            if (typeof ref === 'function') {
                ref(value)
            } else if (ref) {
                // @ts-ignore
                ref.current = value
            }
        }
    }
}
