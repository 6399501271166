import React from 'react'
import PropTypes from 'prop-types'
import { StyledFormHeader, PageTitle, Container } from '@snsw-gel/page'
import { ProgressStepper } from '@snsw-gel/progress-stepper'

const FormHeader = ({ className, label, title, stepsList }) => (
    <StyledFormHeader className={className}>
        <Container>
            {label && <p>{label}</p>}
            <PageTitle>{title}</PageTitle>
            {stepsList && <ProgressStepper stepsList={stepsList} />}
        </Container>
    </StyledFormHeader>
)

FormHeader.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string.isRequired,
    stepsList: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string,
            status: PropTypes.oneOf(['completed', 'current', 'todo']),
        }),
    ),
}

export default FormHeader
