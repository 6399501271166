import { createVar, vars } from '@snsw-gel/theming'
import styled from 'styled-components'

export const popOverZIndexVar = createVar('popover-z-index', 'unset')
export const popOverTransitionDurationVar = createVar(
    'popover-transition-duration',
    vars.transitions.hover.toString(),
)
export const popOverTransformOriginVar = createVar(
    'popover-transform-origin',
    'top center',
)
export const popOverTransformStartVar = createVar(
    'popover-transform-start',
    `scale(0.9) translate(0, -10px)`,
)
export const popOverTransformEndVar = createVar(
    'popover-transform-end',
    `scale(1) translate(0, 0px)`,
)

export const StyledPopOver = styled.div`
    transform: ${popOverTransformStartVar};
    opacity: 0;
    transition-property: transform, opacity;
    transition-timing-function: ${vars.easings.natural};
    transition-duration: ${popOverTransitionDurationVar};
    transform-origin: ${popOverTransformOriginVar};
    z-index: ${popOverZIndexVar};

    &.--mounted,
    &.--entered {
        transform: ${popOverTransformEndVar};
        opacity: 1;
    }
`
export const PopOverShade = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: none;

    z-index: calc(${popOverZIndexVar} - 1);
    height: 100%;

    opacity: 0;
    transition: opacity ${popOverTransitionDurationVar};

    &.--mounted,
    &.--entered {
        opacity: 1;
    }
`
