import styled from 'styled-components'
import { IconClose } from '@snsw-gel/icons'
import { vars } from '@snsw-gel/theming'

export const FullScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const FullScreenOpac = styled(FullScreen)`
    background-color: #848484;
    opacity: 0.8;
    transition: opacity ${vars.transitions.idle} ${vars.easings.natural};

    &.out {
        opacity: 0;
    }
`

export const StyledSlidingModal = styled.div`
    position: relative;
    min-height: 4rem;
    z-index: 2001;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    justify-content: flex-start;
`

StyledSlidingModal.DismissButton = styled.button`
    position: absolute;
    top: 0;
    left: -2rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2e5299;
    border: none;
`

StyledSlidingModal.CloseIcon = styled(IconClose).attrs({ color: '#FFF' })`
    overflow: visible;
`
