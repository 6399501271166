import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconChevronDownProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconChevronDown = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconChevronDownProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M16 5a1 1 0 00-1.71-.71L8 10.59l-6.29-6.3A1.004 1.004 0 00.29 5.71l7 7a1 1 0 001.42 0l7-7A1 1 0 0016 5z' />
        </svg>
    )
}
