import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Box = styled.div`
    background: #f4f7f9;
    margin: 3rem 0;
    padding: 1.25rem;
    max-width: 769px;
    *:first-child {
        margin-top: 0;
    }
    *:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 480px) {
        margin: 2rem 0;
    }
    @media print {
        border: 2px solid #dee3e5;
    }
`

Box.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

Box.displayName = 'Box'
