import styled from 'styled-components'
import { getSpacing, pxToRem } from '@snsw-gel/utils'
import { mq } from '@snsw-gel/theming'

export const BreadcrumbContainer = styled.nav`
    display: flex;
    align-items: baseline;
    a {
        font-size: ${pxToRem(14)};
        text-decoration: underline;
        display: inline-block;
        color: inherit;
        margin-left: ${getSpacing([8])};
        margin-right: ${getSpacing([8])};
    }
    svg {
        height: ${pxToRem(8)};
        width: ${pxToRem(8)};
        fill: currentColor;
    }
`

export const BreadcrumbList = styled.ol`
    margin: 0;
    padding-left: 0;
    list-style: none;
    display: inline;
`

export const BreadcrumbListItem = styled.li`
    display: inline-block;
    align-items: center;
    padding-left: 0;
    margin: 0;

    &.hide-on-mobile {
        display: none;
    }

    svg {
        display: inline;
    }

    &:first-child,
    &:nth-last-child(2) {
        svg {
            display: none;
        }
        a {
            margin-left: 0;
        }
    }

    ${mq.min('tablet')} {
        &:first-child,
        &.hide-on-mobile {
            display: inline-flex;
            align-items: center;
        }

        &:not(:first-child):nth-last-child(2) {
            svg {
                display: inline;
            }
            a {
                margin-left: ${getSpacing([8])};
            }
        }
    }
`
