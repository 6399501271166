import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconCloseProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconClose = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconCloseProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M15 16a1 1 0 01-.71-.29l-14-14A1 1 0 011.71.29l14 14a1 1 0 010 1.42A1 1 0 0115 16z' />
            <path d='M1 16a1 1 0 01-.71-.29 1 1 0 010-1.42l14-14a1 1 0 111.42 1.42l-14 14A1 1 0 011 16z' />
        </svg>
    )
}
