interface StorageItemInterface {
    get(): string | null
    set(value: string): void
    remove(): void
    has(): boolean
    clear(): void
}
const DEPRECATIONS_KEY = '[deps]'

class StorageItem implements StorageItemInterface {
    key: string
    backer: Storage
    cache: string | null = null
    constructor(key: string, storageBacker: Storage = localStorage) {
        this.key = key
        this.backer = storageBacker
    }

    get(): string | null {
        const cachedVal = this.cache
        if (cachedVal === undefined) {
            const localVal = this.backer.getItem(this.key)
            if (localVal !== null) {
                this.cache = localVal
            }
        }
        return cachedVal ?? null
    }

    set(value: string) {
        this.backer.setItem(this.key, value)
        this.cache = value
    }

    remove() {
        this.backer.removeItem(this.key)
        this.cache = null
    }
    clear() {
        this.backer.removeItem(this.key)
        this.cache = null
    }
    has() {
        return this.get() !== null
    }
}

const noopStorageStorageItem = (): StorageItemInterface => ({
    get() {
        return null
    },
    set(value) {},
    remove() {},
    clear() {},
    has() {
        return false
    },
})

export const storage: StorageItemInterface =
    typeof localStorage === 'undefined'
        ? noopStorageStorageItem()
        : new StorageItem(DEPRECATIONS_KEY)
