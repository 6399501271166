import styled, { css } from 'styled-components'
import { pxToRem } from '@snsw-gel/utils'
import { mq, vars } from '@snsw-gel/theming'
import { PopOver } from './Popover'
import {
    popOverTransformEndVar,
    popOverTransformOriginVar,
    popOverTransformStartVar,
} from './StyledPopOver.styled'

const daypickerStyles = css`
    .rdp {
        --rdp-cell-size: 36px; /* Size of the day cells. */
        --rdp-cell-width: var(--rdp-cell-size); /* Width of the day cells. */
        --rdp-cell-height: var(--rdp-cell-size); /* Height of the day cells. */
        --rdp-caption-font-size: 22px; /* Font size for the caption labels. */
        --rdp-accent-color: #0000ff; /* Accent color for the background of selected days. */
        --rdp-background-color: #e7edff; /* Background color for the hovered/focused elements. */
        --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
        --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
        --rdp-outline: 2px solid ${vars.colors.focus}; /* Outline border for focused elements */
        --rdp-outline-selected: 3px solid ${vars.colors.focus}; /* Outline border for focused _and_ selected elements */
        --rdp-selected-color: #fff; /* Color of selected day text */

        > * {
            box-sizing: border-box;
        }

        ${mq.max('lgMobile')} {
            --rdp-cell-width: auto;
        }
    }

    .date-close {
        -webkit-appearance: none;
        align-items: center;
        appearance: none;
        background: ${vars.colors.bgAccent.low};
        border: 0;
        border-radius: 50%;
        color: var(--duet-color-text);
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 0;
        width: ${vars.spacing.lg};
        height: ${vars.spacing.lg};
        color: ${vars.colors.bg.text};
        opacity: 0;

        &:hover {
            box-shadow: 0 0 0 2px var(--duet-color-primary);
            background-color: rgb(222, 227, 229);
        }

        svg {
            display: none;
        }
        &:before {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 16a1 1 0 01-.71-.29l-14-14A1 1 0 011.71.29l14 14a1 1 0 010 1.42A1 1 0 0115 16z'/%3E%3Cpath d='M1 16a1 1 0 01-.71-.29 1 1 0 010-1.42l14-14a1 1 0 111.42 1.42l-14 14A1 1 0 011 16z'/%3E%3C/svg%3E");
            width: ${pxToRem(12)};
            height: ${pxToRem(12)};
            background-size: ${pxToRem(12)} ${pxToRem(12)};
            display: block;

            @media (-ms-high-contrast: active), (forced-colors: active) {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M15 16a1 1 0 01-.71-.29l-14-14A1 1 0 011.71.29l14 14a1 1 0 010 1.42A1 1 0 0115 16z'/%3E%3Cpath fill='white' d='M1 16a1 1 0 01-.71-.29 1 1 0 010-1.42l14-14a1 1 0 111.42 1.42l-14 14A1 1 0 011 16z'/%3E%3C/svg%3E");
            }
        }

        &:focus {
            opacity: 1;
            outline: 2px solid ${vars.colors.focus};
            outline-offset: 2px;
        }
    }

    /* Hide elements for devices that are not screen readers */
    .rdp-vhidden {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        position: absolute !important;
        top: 0;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        overflow: hidden !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        border: 0 !important;
    }

    /* Buttons */
    .rdp-button_reset {
        appearance: none;
        position: relative;
        margin: 0;
        padding: 0;
        cursor: default;
        color: inherit;
        background: none;
        font: inherit;

        -moz-appearance: none;
        -webkit-appearance: none;
    }

    .rdp-button_reset:focus-visible {
        /* Make sure to reset outline only when :focus-visible is supported */
        outline: none;
    }

    .rdp-button {
        border: 2px solid transparent;

        @media (-ms-high-contrast: active), (forced-colors: active) {
            border: none;
            svg {
                fill: currentColor;
            }
        }
    }

    .rdp-button[disabled]:not(.rdp-day_selected) {
        opacity: 0.25;
    }

    .rdp-button:not([disabled]) {
        cursor: pointer;
    }

    .rdp-button:focus-visible:not([disabled]):not(.rdp-day_selected) {
        color: inherit;
        background-color: ${vars.colors.bgAccent.low};
        box-shadow: ${vars.colors.focus} 2px solid;
    }

    .rdp-months {
        display: flex;
    }

    .rdp-month {
        margin: 0 1em;
    }

    .rdp-month:first-child {
        margin-left: 0;
    }

    .rdp-month:last-child {
        margin-right: 0;
    }

    .rdp-table {
        margin: 0;
        margin-right: -6px;
        max-width: calc(var(--rdp-cell-width) * 7);
        border-collapse: collapse;
    }

    .rdp-with_weeknumber .rdp-table {
        max-width: calc(var(--rdp-cell-width) * 8);
        border-collapse: collapse;
    }

    ${mq.max('lgMobile')} {
        .rdp-table,
        .rdp-months,
        .rdp-month {
            max-width: unset;
            min-width: 100%;
        }
    }

    .rdp-caption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 100%;
        margin-bottom: 16px;
    }

    .rdp-multiple_months .rdp-caption {
        position: relative;
        display: block;
        text-align: center;
    }

    .rdp-nav {
        white-space: nowrap;
    }

    .rdp-multiple_months .rdp-caption_start .rdp-nav {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .rdp-multiple_months .rdp-caption_end .rdp-nav {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    .rdp-nav_button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        padding: 0.25em;
        border-radius: 100%;
        background: ${vars.colors.bgAccent.low};
        margin-left: 8px;

        &:hover {
            background-color: rgb(222, 227, 229);
        }
        &:focus {
            outline: 2px solid ${vars.colors.focus};
            outline-offset: 2px;
        }
    }

    /* ---------- */
    /* Dropdowns  */
    /* ---------- */

    .rdp-dropdown_year,
    .rdp-dropdown_month {
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-top: 4px;
    }

    .rdp-caption_dropdowns {
        position: relative;
        display: flex;
        align-items: center;
    }

    .rdp-caption_label {
        position: relative;
        z-index: 1;
        display: inline-flex;
        align-items: center;
        margin: 0;
        border-radius: 6px;
        white-space: nowrap;
        color: currentColor;
        font-family: inherit;
        font-size: 1.375rem;
        font-weight: ${vars.font.weight.bold};
        line-height: 1.4;
        padding: 0 4px 0 8px;

        @media (-ms-high-contrast: active), (forced-colors: active) {
            svg {
                fill: currentColor;
            }
        }
    }

    .rdp-dropdown {
        appearance: none;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        border: none;
        background-color: transparent;
        font-family: inherit;
        font-size: 1rem;
        margin-top: 4px;
        background: rebeccapurple;

        line-height: inherit;
    }

    .rdp-dropdown[disabled] {
        opacity: unset;
        color: unset;
    }

    .rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
        box-shadow: none;
        outline: 2px solid ${vars.colors.focus};
        outline-offset: 2px;

        @media (-ms-high-contrast: active), (forced-colors: active) {
            -ms-high-contrast-adjust: none;
            forced-color-adjust: none;
            outline-color: Highlight;
            color: white;
        }
    }

    .rdp-dropdown_icon {
        margin: 0 0 0 5px;
    }

    .rdp-head {
        border: 0;
    }

    .rdp-head_row,
    .rdp-row {
        height: 100%;
    }

    .rdp-head_cell {
        vertical-align: middle;
        font-size: 0.75rem;
        font-weight: 700;
        text-align: center;
        height: 100%;
        height: 24px;
        line-height: 1.25;
        padding: 1px 1px 8px 1px;
        text-transform: uppercase;
    }

    .rdp-tbody {
        border: 0;
    }

    .rdp-tfoot {
        margin: 0.5em;
    }

    /* option {
        padding: 0 4px 0 8px;
        margin: 0;
    } */

    .rdp-cell {
        width: var(--rdp-cell-width);
        height: 100%;
        height: var(--rdp-cell-height);
        padding: 1px;
        text-align: center;
    }

    .rdp-weeknumber {
        font-size: 0.75em;
    }

    .rdp-weeknumber,
    .rdp-day {
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: var(--rdp-cell-size);
        max-width: var(--rdp-cell-size);
        height: var(--rdp-cell-size);
        margin: 0 auto;
        border: 2px solid transparent;
        border-radius: 100%;
        font-variant-numeric: tabular-nums;

        font-weight: ${vars.font.weight.medium};
        font-size: ${vars.font.size.xs};

        &:focus,
        &:active {
            box-shadow: none;
            outline: 2px solid ${vars.colors.focus};
            outline-offset: 2px;
        }
        &:hover {
            background: ${vars.colors.bgAccent.low};
            color: ${vars.colors.forms.text};
        }

        @media (-ms-high-contrast: active), (forced-colors: active) {
            border: none;
        }
    }

    .rdp-day_today:not(.rdp-day_outside) {
        font-weight: bold;
    }

    .rdp-day_selected,
    .rdp-day_selected:focus-visible,
    .rdp-day_selected:hover {
        color: ${vars.colors.bg.default};
        opacity: 1;
        background-color: ${vars.colors.focus};

        @media (-ms-high-contrast: active), (forced-colors: active) {
            -ms-high-contrast-adjust: none;
            forced-color-adjust: none;
            background-color: Highlight;
            border-color: Highlight;
            color: HighlightText;
        }
    }

    .rdp-day_outside {
        font-weight: ${vars.font.weight.book};
        color: #70757f;
    }

    .rdp-day_outside:hover {
        font-weight: ${vars.font.weight.book};
        color: #6c707a;
    }

    .rdp-day_selected:focus-visible {
        /* Since the background is the same use again the outline */
        outline: var(--rdp-outline);
        outline-offset: 2px;
        z-index: 1;

        @media (-ms-high-contrast: active), (forced-colors: active) {
            -ms-high-contrast-adjust: none;
            forced-color-adjust: none;
            outline-color: Highlight;
            background-color: Highlight;
            border-color: Highlight;
        }
    }

    .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .rdp-day_range_end.rdp-day_range_start {
        border-radius: 100%;
    }

    .rdp-day_range_middle {
        border-radius: 0;
    }

    /*# sourceMappingURL=style.css.map */
`

export const DatePickerContainer = styled.div`
    position: relative;

    ${daypickerStyles}
`

export const DatePickerPanel = styled(PopOver)`
    position: absolute;
    top: 100%;
    margin-top: 8px;
    right: 0;
    z-index: 600;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    margin-top: 8px;
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    ${popOverTransformOriginVar.set('top right')};

    background: ${vars.colors.bg.default};

    .date-picker__panel-header {
        label {
            display: none;
        }

        .date-close {
            position: absolute;
            top: -8px;
            right: -8px;
        }
    }

    ${mq.max('lgMobile')} {
        position: fixed;
        bottom: 0;
        top: unset;
        width: 100%;
        padding: 0px 1.25rem 3rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        ${popOverTransformOriginVar.set('bottom center')};
        ${popOverTransformStartVar.set('scale(1) translate(0, 50%)')};
        ${popOverTransformEndVar.set('scale(1) translate(0, 0)')};

        .date-picker__panel-header {
            padding: 16px 22px;
            margin: 0 -22px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            border-bottom: solid 1px ${vars.colors.bg.border};

            label {
                display: block;
                font-weight: ${vars.font.weight.bold};
            }

            .date-close {
                position: static;
                opacity: 1;
                top: 0;
                right: 0;
            }
        }
    }
`

export const DatePickerButton = styled.button`
    position: absolute;
    border: none;
    background: ${vars.colors.bgAccent.low};
    right: 2px;
    top: 6px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: ${pxToRem(44)};
    width: ${vars.spacing.xxl};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
        cursor: pointer;
    }
    &:focus {
        outline: 2px solid ${vars.colors.focus};
        outline-offset: 4px;
    }
    &:disabled {
        color: ${vars.colors.disabled.text};
        background-color: ${vars.colors.disabled.off};
        cursor: not-allowed;
        -webkit-transition: none;
        transition: none;

        svg {
            fill: ${vars.colors.disabled.text};
        }
    }
    &.is-hidden {
        display: none;
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        svg {
            fill: currentColor;
        }
    }
`
