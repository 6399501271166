/* eslint-disable max-len */
import React from 'react'
import classNames from 'classnames'
import { SuccessAnimationContainer } from './AnimatedIconSuccess.styled'
import { useHasInitialised } from '@snsw-gel/utils'

const AnimatedIconSuccess = () => {
    const drawn = useHasInitialised()

    return (
        <SuccessAnimationContainer>
            <div className={classNames({ drawn })} />
            <svg viewBox='0 0 70 70'>
                <path
                    className='circle path'
                    d='M35,70 C15.6700338,70 0,54.3299662 0,35 C0,15.6700338 15.6700338,0 35,0 C54.3299662,0 70,15.6700338 70,35 C70,44.2825771 66.3125106,53.1849641 59.7487373,59.7487373 C53.1849641,66.3125106 44.2825771,70 35,70 Z'
                />
                <polyline
                    className='tick path'
                    points='21.875 37.1875 30.625 45.9375 48.125 24.0625'
                />
            </svg>
        </SuccessAnimationContainer>
    )
}

export default AnimatedIconSuccess
