import styled from 'styled-components'
import {
    getBreakpoint,
    getSpacing,
    pxToRem,
    getTypeSize,
} from '@snsw-gel/utils'
// @ts-ignore
import { colors, layouts } from '@snsw-gel/tokens'
import { vars } from '@snsw-gel/theming'

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2000;

    ${getBreakpoint.sm`
        align-items: center;
    `}
`

export const BgClicker = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`

export const StyledModal = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    max-height: 100%;
    width: 100%;
    max-width: ${pxToRem(708)};
    z-index: 2;

    a {
        text-decoration: underline;
    }

    ${getBreakpoint.sm`
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        min-width: ${pxToRem(400)};
        margin-left: ${pxToRem(layouts.containers.gutters.mobile)};
        margin-right: ${pxToRem(layouts.containers.gutters.mobile)};
    `}

    ${getBreakpoint.md`
        margin-left: ${pxToRem(layouts.containers.gutters.desktop)};
        margin-right: ${pxToRem(layouts.containers.gutters.desktop)};
    `}
`

export const StyledModalContainer = styled.div`
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${colors.modal.background};

    @media (-ms-high-contrast: active), (forced-colors: active) {
        border: solid 2px transparent;
    }

    ${getBreakpoint.sm`
        border-radius: 8px;
    `}

    ${getBreakpoint.md`
        max-height: 90vh;
    `}
`

export const StyledModalHeader = styled.div`
    align-items: flex-start;
    flex-shrink: 0;
    padding: ${getSpacing(['md', 'md', 'xs', 'none'])};
    width: 100%;
    position: relative;

    h2 {
        margin: 0;
    }

    ${getBreakpoint.sm`
        padding: ${getSpacing(['md', 'md', 'xs', 'none'])};
    `}
`

export const StyledModalTitle = styled.h1`
    ${getTypeSize('xl', 'heading')};
    margin: 0 !important;
`

export const StyledModalBody = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

export const StyledModalOverflow = styled.div`
    position: relative;
    padding: ${getSpacing(['none', 20])};
    overflow-y: auto;
    transition: border-color 0.5s ease;

    ${getBreakpoint.sm`
        padding: ${getSpacing(['none', 'lg'])};
    `}
`

export const StyledModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
    padding: ${getSpacing(['sm', 'none', 'xxl', 'none'])};
    padding-bottom: calc(${getSpacing(['xxl'])} + env(safe-area-inset-bottom));

    ${getBreakpoint.sm`
        padding: ${getSpacing(['lg', 'none', 'xxl', 'none'])};
    `}

    ${getBreakpoint.md`
        padding: ${getSpacing(['lg', 'none'])};
    `}

    &.overflow-padding {
        padding: ${getSpacing(['sm', 'none', 'xxl', 'none'])};

        ${getBreakpoint.sm`
            padding: ${getSpacing(['sm', 'none', 'xxl', 'none'])};
        `}

        ${getBreakpoint.md`
            padding: ${getSpacing(['sm', 'none', 'lg', 'none'])};
        `}
    }
`

export const StyledModalButtonGroup = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;
    z-index: 1;

    button {
        width: 100%;

        + button {
            margin-bottom: ${pxToRem(16)};
        }
    }

    ${getBreakpoint.md`
        flex-wrap: nowrap;
        flex-direction: row;

        button {
            width: auto;

            + button{
                margin-bottom: 0;
                margin-left: ${pxToRem(16)};
            }
        }
    `}
`

export const StyledModalCloseButton = styled.button`
    position: absolute;
    top: ${pxToRem(8)};
    right: ${pxToRem(8)};
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    background: none;
    border: none;

    @media (-ms-high-contrast: active), (forced-colors: active) {
        fill: ${vars.colors.bg.default};
    }

    &:focus {
        outline: 2px solid #2e5299;
        outline-offset: 2px;
    }

    svg {
        color: #000;
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
    }
`
