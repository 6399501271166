import React, { useRef, ChangeEvent, cloneElement } from 'react'
import {
    DatePickerContainer,
    DatePickerButton,
    DatePickerPanel,
} from './DatePicker.styled'
import { Input } from '@snsw-gel/input'
import { useMatchMedia, useId } from '@snsw-gel/utils'
import { legacyValidators } from '@snsw-gel/dates'
import {
    IconCalendar,
    IconChevronDown,
    IconChevronLeft,
    IconChevronRight,
    IconClose,
} from '@snsw-gel/icons'
import { SROnly } from '@snsw-gel/accessibility'
import {
    Button,
    Day,
    DayContentProps,
    DayPicker,
    DayProps,
    DropdownProps,
    IconDropdown,
    RowProps,
    useDayPicker,
    useDayRender,
    WeekNumber,
} from 'react-day-picker'
import {
    add,
    addDays,
    format,
    getUnixTime,
    Locale,
    parse,
    setDate,
    setMonth,
    startOfISOWeek,
    startOfWeek,
} from 'date-fns'
import { OverlayTriggerProps, useComboPickerState } from './useComboPickerState'
import { FieldLabel } from '@snsw-gel/field'
import { mq } from '@snsw-gel/theming'
import { PopOverProps } from './Popover'

export const createSyntheticChangeEvent = <
    T extends HTMLElement & EventTarget,
    E extends Omit<Event, 'target' | 'currentTarget'> & {
        target: T
        currentTarget: T
    },
>(
    event: E,
): React.ChangeEvent<T> => {
    let isDefaultPrevented = false
    let isPropagationStopped = false
    const preventDefault = () => {
        isDefaultPrevented = true
        event.preventDefault()
    }
    const stopPropagation = () => {
        isPropagationStopped = true
        event.stopPropagation()
    }
    return {
        nativeEvent: event,
        currentTarget: event.currentTarget,
        target: event.target,
        bubbles: event.bubbles,
        cancelable: event.cancelable,
        defaultPrevented: event.defaultPrevented,
        eventPhase: event.eventPhase,
        isTrusted: event.isTrusted,
        preventDefault,
        isDefaultPrevented: () => isDefaultPrevented,
        stopPropagation,
        isPropagationStopped: () => isPropagationStopped,
        persist: () => {},
        timeStamp: event.timeStamp,
        type: event.type,
    }
}

interface ValidDateFormat {
    isValid: true
    value: string
    isoDate: string
    shortDate: string
    longDate: string
}

interface InvalidDateFormat {
    isValid: false
    value: string
}

type DateFormats = ValidDateFormat | InvalidDateFormat

interface DatePickerProps
    extends OverlayTriggerProps,
        Pick<PopOverProps, 'trapFocus'> {
    id?: string
    name?: string

    disabled?: boolean

    onBlur?: (e: React.FocusEvent<HTMLInputElement>, value: DateFormats) => void
    /** Must be in IS0-8601 format: YYYY-MM-DD */
    min?: string
    /** Must be in IS0-8601 format: YYYY-MM-DD */
    max?: string
    label?: string

    showCalendar?: boolean

    /**
     * @deprecated
     * @hidden
     */
    trapFocus?: boolean

    /**
     *
     */
    selected?: Date
    defaultSelected?: Date
    onSelectionChange?: (selected: Date | null) => void

    /**
     * Value is deprecated in favor of selected and inputValue
     * @deprecated
     * */
    value?: string
    /**
     * onChange is deprecated in favor of onSelected and onInputChange
     * @deprecated
     */
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement>,
        value: DateFormats,
    ) => void

    inputValue?: string
    onInputChange?: (value: string) => void
    defaultInputValue?: string
}

function getIsValidFormat(value: string) {
    return legacyValidators.validDateFormat(value, 'DD/MM/YYYY')
}

function getLegacyDateValue(dateString: string) {
    const isValid = getIsValidFormat(dateString)

    let eventValue: InvalidDateFormat | ValidDateFormat = {
        isValid: false,
        value: dateString,
    }

    if (isValid) {
        const eventDate = parse(dateString, 'dd/MM/yyyy', new Date())
        eventValue = {
            isoDate: format(eventDate, 'yyyy-MM-dd'),
            isValid: true,
            longDate: format(eventDate, 'dd MMM yyyy'),
            shortDate: dateString,
            value: dateString,
        }
    }
    return eventValue
}

export const DatePicker = (props: DatePickerProps) => {
    const {
        id,
        name,
        disabled,
        onBlur,
        label,
        min,
        max,
        showCalendar = true,

        defaultOpen,
        isOpen: propsIsOpen,
        onOpenChange,

        trapFocus = true,

        value: propsValue,
        onChange,
        inputValue: propsInputValue,
        onInputChange,
        defaultInputValue,
        selected: propsSelected,
        defaultSelected,
        onSelectionChange: onSelected,
        ...rest
    } = props

    const inputRef = useRef<HTMLInputElement>(null)

    function getInputValueFromSelected(date: Date) {
        return format(date, 'dd/MM/yyyy')
    }
    const getSelectedFromInputValue = (value: string) => {
        const result = legacyValidators.validDateFormat(value, 'DD/MM/YYYY')
            ? parse(value, 'dd/MM/yyyy', new Date())
            : null
        return result
    }

    const {
        isOpen,
        open,
        close,
        toggle,
        inputValue,
        selectedValue: selectedDate,
        setFocusedSelection,
        setInputValue,
        setSelectedValue,
        setFocusedInput,
        setOpen,
    } = useComboPickerState<Date>({
        allowsCustomValue: true,
        shouldCommitOnBlur: true,
        defaultInputValue,

        isOpen: propsIsOpen,
        onOpenChange,
        defaultOpen,

        defaultSelected,
        selected: propsSelected,
        onSelected: selected => {
            if (selected) {
                setCurrentMonth(selected)
            }
            onSelected?.(selected)

            if (onChange) {
                onChange(
                    {
                        target: {
                            value: selected
                                ? getInputValueFromSelected(selected)
                                : inputValue,
                            name,
                            id,
                        },
                        type: 'duetChange',
                        stopPropagation: () => {},
                        preventDefault: () => {},
                    } as any,
                    getLegacyDateValue(
                        selected
                            ? getInputValueFromSelected(selected)
                            : inputValue,
                    ),
                )
            }
        },

        getInputValueFromSelected,
        getSelectedFromInputValue,
        inputValue: propsInputValue ?? propsValue,

        onInputChange: inputValue => {
            if (onInputChange) {
                onInputChange(inputValue)
            } else if (onChange) {
                // emulate an event
                const changeEvent = new Event('change', {
                    bubbles: false,
                    cancelable: false,
                    composed: false,
                })

                Object.defineProperties(changeEvent, {
                    target: {
                        get() {
                            return inputRef.current!
                        },
                    },
                    currentTarget: {
                        get() {
                            return inputRef.current!
                        },
                    },
                    srcElement: {
                        get() {
                            return inputRef.current!
                        },
                    },
                })

                onChange(
                    // @ts-ignore
                    createSyntheticChangeEvent(changeEvent),
                    getLegacyDateValue(inputValue),
                )
            }
        },
    })

    const [currentMonth, setCurrentMonth] = React.useState(() =>
        selectedDate ? selectedDate : new Date(),
    )

    const buttonRef = useRef(null)
    const panelRef = useRef<HTMLDivElement>(null)

    const [isMobile] = useMatchMedia(mq.max('lgMobile'))

    const labeledById = useId()

    function onInputBlurHandler() {
        if (onBlur) {
            onBlur(
                {
                    target: {
                        value: inputValue,
                        name,
                        id,
                    },
                    type: 'blur',
                    stopPropagation: () => {},
                    preventDefault: () => {},
                } as any,
                getLegacyDateValue(inputValue),
            )
        }
    }

    const fromDate = min
        ? parse(min, 'yyyy-MM-dd', new Date())
        : new Date(currentMonth.getFullYear() - 10, 0, 1)
    const toDate = max
        ? parse(max, 'yyyy-MM-dd', new Date())
        : setDate(setMonth(add(new Date(), { years: 10 }), 11), 31)

    return (
        <DatePickerContainer>
            <input
                name={(name ? name + '-' : '') + 'date'}
                value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
                type='hidden'
            />
            <Input
                ref={inputRef}
                value={inputValue}
                onChange={e => setInputValue(e.currentTarget.value)}
                onFocus={() => {
                    setFocusedInput(true)
                }}
                onBlur={() => {
                    setFocusedInput(false)
                    onInputBlurHandler()
                }}
                id={id}
                name={name}
                disabled={disabled}
                {...rest}
            />
            {showCalendar && (
                <DatePickerButton
                    ref={buttonRef}
                    onClick={() => toggle(null, 'manual')}
                    disabled={disabled}
                    type='button'
                >
                    <SROnly>
                        {selectedDate
                            ? `Change date, ${format(
                                  selectedDate,
                                  'do MMMM yyyy',
                              )}`
                            : 'Choose date'}
                    </SROnly>
                    <IconCalendar color='secondaryBlue' />
                </DatePickerButton>
            )}
            <DatePickerPanel
                close={close}
                isOpen={isOpen}
                open={open}
                ref={panelRef}
                setOpen={setOpen}
                shade={isMobile}
                toggle={toggle}
                closeOnEscape
                closeOnOutsideClick
                aria-labelledby={labeledById}
                trapFocus={trapFocus}
                focusOnEnter={container =>
                    container?.querySelector('[name="months"]')
                }
                focusOnExit={() => buttonRef.current}
            >
                <div className='date-picker__panel-header'>
                    <SROnly
                        id={labeledById}
                        aria-live='polite'
                        aria-atomic='true'
                        as='h2'
                    >
                        {format(currentMonth, 'MMMM yyyy')}
                    </SROnly>
                    <FieldLabel>{label}</FieldLabel>
                    <button onClick={() => close()} className='date-close'>
                        <IconClose />
                        <SROnly>Close window</SROnly>
                    </button>
                </div>
                <DayPicker
                    month={currentMonth}
                    onMonthChange={setCurrentMonth}
                    mode='single'
                    fromDate={fromDate}
                    toDate={toDate}
                    showOutsideDays
                    selected={selectedDate ?? undefined}
                    captionLayout='dropdown-buttons'
                    weekStartsOn={1}
                    aria-labelledby={labeledById}
                    onDayFocus={day => {
                        setFocusedSelection(day)
                    }}
                    onDayBlur={() => {
                        setFocusedSelection(null)
                    }}
                    onSelect={date => {
                        setSelectedValue(date || null)
                    }}
                    components={{
                        Dropdown: CustomDropdown,
                        CaptionLabel: CustomCaptionLabel,
                        IconDropdown: Chevron,
                        IconLeft: ChevronLeft,
                        IconRight: ChevronRight,
                        Day: CustomDay,
                        Row: CustomRow,
                        DayContent: CustomDayContent,
                        HeadRow,
                    }}
                    formatters={{
                        formatMonthCaption: (month, opts) => {
                            return new Intl.DateTimeFormat(opts?.locale?.code, {
                                month: 'short',
                            }).format(month)
                        },
                    }}
                />
            </DatePickerPanel>
        </DatePickerContainer>
    )
}

function getWeekdays(
    locale?: Locale,
    /** The index of the first day of the week (0 - Sunday). */
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
    /** Use ISOWeek instead of locale/ */
    ISOWeek?: boolean,
): Date[] {
    const start = ISOWeek
        ? startOfISOWeek(new Date())
        : startOfWeek(new Date(), { locale, weekStartsOn })

    const days = []
    for (let i = 0; i < 7; i++) {
        const day = addDays(start, i)
        days.push(day)
    }
    return days
}

export function HeadRow(): JSX.Element {
    const {
        classNames,
        styles,
        showWeekNumber,
        locale,
        weekStartsOn,
        ISOWeek,
        formatters: { formatWeekdayName },
        labels: { labelWeekday },
    } = useDayPicker()

    const weekdays = getWeekdays(locale, weekStartsOn, ISOWeek)

    return (
        <tr style={styles.head_row} className={classNames.head_row}>
            {showWeekNumber && (
                <td style={styles.head_cell} className={classNames.head_cell} />
            )}
            {weekdays.map((weekday, i) => (
                <th
                    key={i}
                    scope='col'
                    className={classNames.head_cell}
                    style={styles.head_cell}
                >
                    <SROnly>{labelWeekday(weekday, { locale })}</SROnly>
                    <span aria-hidden='true'>
                        {formatWeekdayName(weekday, { locale })}
                    </span>
                </th>
            ))}
        </tr>
    )
}

const CustomDayContent = (props: DayContentProps) => {
    return (
        <>
            <span aria-hidden='true'>{format(props.date, 'd')}</span>
            <SROnly>{format(props.date, 'MMMM d')}</SROnly>
        </>
    )
}

const CustomDay = (props: DayProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const dayRender = useDayRender(props.date, props.displayMonth, buttonRef)

    if (dayRender.isHidden) {
        return <div />
    }
    if (!dayRender.isButton) {
        return <div {...dayRender.divProps} />
    }

    const {
        // @ts-ignore
        role: _,
        ['aria-selected']: ariaSelected,
        ...buttonProps
    } = dayRender.buttonProps

    return (
        <Button
            name='day'
            ref={buttonRef}
            {...buttonProps}
            aria-pressed={ariaSelected}
        />
    )
}

const ChevronLeft = () => {
    return <IconChevronLeft width='0.75rem' height='0.75rem' />
}
const ChevronRight = () => {
    return <IconChevronRight width='0.75rem' height='0.75rem' />
}

const Chevron = () => {
    return <IconChevronDown width={12} height={12} style={{ marginLeft: 8 }} />
}

const CustomDropdown = ({ ...props }: DropdownProps) => {
    // let options: ReactNode[] = []

    if (props.name === 'months') {
        return (
            <Dropdown {...props}>
                {React.Children.map(props.children, child => {
                    if (!child) {
                        return child
                    }
                    if (React.isValidElement(child)) {
                        const childText =
                            typeof child.props.children === 'string'
                                ? format(
                                      parse(
                                          child.props.children,
                                          'LLL',
                                          new Date(),
                                      ),
                                      'LLLL',
                                  )
                                : ''
                        return cloneElement(child, undefined, childText)
                    }
                })}
            </Dropdown>
        )
    }

    return <Dropdown {...props} />
}

export function Dropdown(props: DropdownProps): JSX.Element {
    const { onChange, value, children, caption, className, style } = props
    const dayPicker = useDayPicker()

    const IconDropdownComponent =
        dayPicker.components?.IconDropdown ?? IconDropdown

    const id = useId()
    return (
        <div className={className} style={style}>
            <SROnly htmlFor={id} as='label'>
                {props['aria-label']}
            </SROnly>
            <select
                id={id}
                name={props.name}
                className={dayPicker.classNames.dropdown}
                style={dayPicker.styles.dropdown}
                value={value}
                onChange={onChange}
            >
                {children}
            </select>
            <div
                className={dayPicker.classNames.caption_label}
                style={dayPicker.styles.caption_label}
                aria-hidden='true'
            >
                {caption}
                <IconDropdownComponent
                    className={dayPicker.classNames.dropdown_icon}
                    style={dayPicker.styles.dropdown_icon}
                />
            </div>
        </div>
    )
}

const CustomCaptionLabel = () => {
    return null
}
const CustomRow = (props: RowProps) => {
    const { styles, classNames, showWeekNumber, components } = useDayPicker()

    const DayComponent = components?.Day ?? Day
    const WeeknumberComponent = components?.WeekNumber ?? WeekNumber

    let weekNumberCell
    if (showWeekNumber) {
        weekNumberCell = (
            <td className={classNames.cell} style={styles.cell}>
                <WeeknumberComponent
                    number={props.weekNumber}
                    dates={props.dates}
                />
            </td>
        )
    }

    return (
        <tr className={classNames.row} style={styles.row}>
            {weekNumberCell}
            {props.dates.map(date => (
                <td
                    className={classNames.cell}
                    style={styles.cell}
                    key={getUnixTime(date)}
                >
                    <DayComponent
                        displayMonth={props.displayMonth}
                        date={date}
                    />
                </td>
            ))}
        </tr>
    )
}
