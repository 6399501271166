import { contract, mq } from './themeContract'
import { createTheme } from './createTheme'

export const gel3LightTheme = createTheme(contract, {
    name: 'gel-3-light',
    type: 'light',
    matches: [mq.light()],
})

export const gel3DarkTheme = createTheme(contract, {
    name: 'gel-3-dark',
    matches: [mq.dark()],
    type: 'dark',
    colors: {
        bg: {
            default: '#121212',
            text: '#fff',
            off: '#9A9DA6',
            border: '#2a2a2a',
            active: '#9A9DA6',
        },
        background: {
            default: '#121212',
        },
        bgAccent: {
            default: '#202020',
            text: '$colors.bg.text',
        },
        success: {
            default: '#008a06',
            off: '#142713',
        },
        warning: {
            off: '#29170F',
        },
        error: {
            off: '#3d1614',
        },
        info: {
            off: '#1c2536',
        },
        forms: {
            border: '$colors.bg.off',
            error: '$colors.accent1.default',
            helpText: '$colors.forms.border',
        },
        accent1: {
            default: '#f38b82',
            text: '$colors.bg.default',
            hover: '#d97568',
            active: '#ab6963',
        },
        accent2: {
            default: '#bac8e4',
            text: '$colors.bg.default',
            hover: '#d97568',
            active: '#ab6963',
        },
        accent3: {
            bg: 'rgb(20 21 26)',
            default: '#bac8e4',
            active: 'rgba(186,200,228, 0.8)',
            text: '$colors.bg.default',
        },
        brand: {
            dark: {
                default: '#f38b82',
                hover: '#d97568',
                active: '#ab6963',
            },
        },
        primary: {
            default: '#CBEDFD',
            hover: '#BDDCEB',
            active: '$colors.primary.default',
            disabled: '#58656B',
            text: '#000000',
            border: '#CBEDFD',
        },
        secondary: {
            default: '#14151A',
            hover: '#BDDCEB',
            active: '#CBEDFD',
            disabled: 'colors.secondary.default',
            border: '#CBEDFD',
            text: '$colors.secondary.border',
            disabledBorder: '#58656B',
        },
        tertiary: {
            default: '#14151A',
            hover: '#BDDCEB',
            active: '#CBEDFD',
            disabled: '#58656B',
            text: '$colors.secondary.border',
        },
        destructive: {
            default: '#F7E7EB',
            hover: '#E6D7DB',
            active: '$colors.destructive.default',
            disabled: '#696364',
            text: '#000000',
        },
        white: '$colors.bg.default',
        link: {
            text: '#bac8e4',
        },
    },
})
