import React, {
    FocusEvent,
    forwardRef,
    HTMLAttributes,
    Ref,
    useRef,
} from 'react'
import { bindRefs } from '@snsw-gel/utils'
import styled from 'styled-components'

export type FocusGroupProps = {
    onFocus?: (event: React.FocusEvent<any>) => void
    onBlur?: (event: React.FocusEvent<any>) => void
}

const Div = styled.div``

type AsProp = { as?: Parameters<typeof Div>[0]['as'] }

/*
  Debounce focusin and focusout so that they are only fired once
  when focus enters/leaves this component
  (elements inside this component can be focussed between without events firing)
*/
function FocusGroup(
    props: HTMLAttributes<HTMLDivElement> & FocusGroupProps & AsProp,
    ref: Ref<HTMLDivElement>,
) {
    const { onFocus, onBlur, ...rest } = props
    const containsFocusRef = useRef(false)
    const containerRef = useRef<HTMLDivElement>(null)

    return (
        <Div
            ref={bindRefs(containerRef, ref)}
            onFocus={(event: FocusEvent) => {
                if (!containsFocusRef.current) {
                    containsFocusRef.current = true
                    if (onFocus) {
                        onFocus(event)
                    }
                }
            }}
            onBlur={(event: FocusEvent) => {
                if (!containerRef.current!.contains(event.relatedTarget)) {
                    containsFocusRef.current = false
                    if (onBlur) {
                        onBlur(event)
                    }
                }
            }}
            {...rest}
        />
    )
}

const _FocusGroup = forwardRef(FocusGroup)
export { _FocusGroup as FocusGroup }
