import React from 'react'
import PropTypes from 'prop-types'
import { SROnly } from '@snsw-gel/accessibility'
import StyledSkeletonContainer from './SkeletonContainer.styled'

export const SkeletonContainer = ({
    className,
    title = 'loading',
    children,
}) => {
    return (
        <StyledSkeletonContainer className={className}>
            <SROnly data-testid='sr-only-title'>{title}</SROnly>
            <div data-testid='hide-items' aria-hidden={true}>
                {children}
            </div>
        </StyledSkeletonContainer>
    )
}
SkeletonContainer.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
}
